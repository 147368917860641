import antdData from 'antd/lib/locale-provider/vi_VN'
import localeData from 'react-intl/locale-data/vi'

const messages = {
  'topBar.title': 'Admin',
  'topBar.profileMenu.user': 'Tên tài khoản',
  'topBar.profileMenu.role': 'Vị trí',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.logout': 'Đăng xuất',
  'topBar.profileMenu.ressetPassword': 'Đổi mật khẩu',
  'topBar.profileMenu.printIP': 'IP Máy in',
  'topBar.profileMenu.hello': 'Xin chào',

  'footer.title': 'Quqo Admin',
  'footer.subTitle': 'Mua và bán tất cả sản phẩm chỉ với 1 ứng dụng ...',
  'footer.url': '© 2022 Quqo Back office',

  'authorize.not': 'Truy cập trái phép',
  'authorize.desNot': 'Bạn không có quyền truy cập trang này!',

  'login.language': 'Ngôn ngữ',
  'login.iniciar': 'Đăng nhập',
  'login.welcome': 'Chào mừng',
  'login.welcomeQuqo': 'Chào mừng bạn đến với Quqo Admin',
  'login.invalidEmail': 'Email hoặc mật khẩu không hợp lệ',
  'login.requiredEmail': 'Email không hợp lệ',
  'login.requiredEmail2': 'Nhập email của bạn',
  'login.requiredpass': 'Vui lòng nhập mật khẩu',
  'login.forgetPassword': 'Bạn đã quên mật khẩu',
  'login.login': 'Đăng nhập',

  'resetPassword.title': 'Cập nhật mật khẩu',
  'resetPassword.confirmOK': 'Mật khẩu mới đã được cập nhật thành công',
  'resetPassword.newPassword': 'Tạo mật khẩu mới',
  'resetPassword.actual': 'Mật khẩu hiện tại',
  'resetPassword.new': 'Mật khẩu mới',
  'resetPassword.newConfirm': 'Xác nhận mật khẩu',
  'resetPassword.PleaseConfirm': 'Vui lòng xác nhận lại mật khẩu',
  'resetPassword.noConfirm': 'Mật khẩu không trùng khớp',
  'resetPassword.actualPassword': 'Vui lòng nhập mật khẩu hiện tại',
  'resetPassword.continue': 'Tiếp tục',
  'resetPassword.backToLogin': 'Quay về Đăng nhập',
  'resetPassword.checkCode': 'Kiểm tra mã số',
  'resetPassword.emailCode1': 'Một mã số vừa được gửi đến',
  'resetPassword.emailCode2': 'Vui lòng kiểm tra email vừa được gửi để nhận mã số từ Quqo',
  'resetPassword.enterCode': 'Nhập mã số',
  'resetPassword.writeCode': 'Vui lòng nhập mã số bạn vừa nhận từ email',
  'resetPassword.VerifyYourIdentity': 'Xác nhận số CCCD',
  'resetPassword.title2': 'Cài đặt lại mật khẩu',
  'resetPassword.enterNewPassword': 'Đặt mật khẩu mới',
  'resetPassword.repeatPassword': 'Nhập lại mật khẩu',
  'resetPassword.PleaseRepeatPassword': 'Vui lòng nhập lại mật khẩu',

  'globals.mobilePhone': 'Số điện thoại',
  'globals.attachments': 'Tệp đính kèm',
  'globals.address': 'Địa chỉ',
  'globals.detail': 'Chi tiết',
  'globals.name': 'Tên',
  'globals.email': 'Email',
  'globals.password': 'Mật khẩu',
  'globals.credential': 'Số CCCD',
  'globals.StoreCredential': 'Số đăng ký kinh doanh',
  'globals.category': 'Danh mục',
  'globals.alert': 'Thông báo',
  'globals.alertError': 'Lỗi',
  'globals.edit': 'Chỉnh sửa',
  'globals.provider': 'Nhà cung ứng',
  'globals.price': 'Giá',
  'globals.TotalPrice': 'Tổng giá',
  'globals.deadline': 'Ngày giao hàng',
  'globals.store': 'Cửa hàng',
  'globals.status': 'Trạng thái',
  'globals.SeeOrder': 'Chi tiết đơn hàng',
  'globals.send': 'Gửi',
  'globals.cancel': 'Huỷ',
  'globals.ok': 'Đúng',
  'globals.cancelModal': 'Không',
  'globals.okSave': 'Lưu',
  'globals.cancelText': 'Huỷ',
  'globals.total': 'Tổng cộng',
  'globals.loading': 'Đang tải…',
  'globals.product': 'Sản phẩm',
  'globals.initialAmount': 'Số lượng đặt hàng',
  'globals.finalAmount': 'Số lượng thực tế',
  'globals.AmounttoReceive': 'Số tiền phải trả',
  'globals.AmountReceive': 'Số tiền đã nhận',
  'globals.unitValue': 'Giá mỗi đơn vị',
  'globals.unitValueInitial': 'Số lượng hàng đặt',
  'globals.unitValueFinal': 'Số lượng hàng đặt',
  'globals.productImage': 'Hình sản phẩm',
  'globals.orderHistory': 'Lịch sử đơn hàng',
  'globals.orderStatusDetail': 'Đang chuẩn bị hàng',
  'globals.noChanges': 'Không thay đổi',
  'globals.columnsControlChanges.title': 'Thay đổi kiểm soát',
  'globals.columnsControlChanges.date': 'Đổi ngày',
  'globals.columnsControlChanges.responsable': 'Người phụ trách',
  'globals.columnsControlChanges.reason': 'Lí do',
  'globals.quantity': 'Số lượng',
  'globals.points': 'Điểm',
  'globals.totalToPay': 'Tổng số phải trả',
  'globals.pilot': 'Nhân viên giao hàng',
  'globals.status.all': 'Tất cả',
  'globals.status.pending': 'Đơn hàng chờ thanh toán',
  'globals.status.confirm': 'Xác nhận đơn hàng',
  'globals.status.cancel': 'Đã huỷ',
  'globals.status.transit': 'Đang giao hàng',
  'globals.status.delivered': 'Đã giao hàng',
  'globals.status.reagended': 'Đổi ngày giao hàng',
  'globals.status.review': 'Kiểm tra',
  'globals.status.deliveryFailed': 'Không thể giao hàng',
  'globals.filterById': 'Lọc theo mã đơn hàng',
  'globals.update': 'Cập nhật',
  'globals.filter': 'Danh sách lọc',
  'globals.description': 'Mô tả',
  'globals.change': 'Thay đổi',
  'globals.zipcode': 'Mã bưu điện',
  'globals.city': 'Thành phố',
  'globals.select': 'Chọn',
  'globals.create': 'Tạo',
  'globals.turnOff': 'Tắt',
  'globals.type': 'Loại hình',
  'globals.role': 'Vai trò',
  'globals.accept': 'Chấp nhận',
  'globals.uploadFile': 'Chọn hoặc kéo file đến mục này để tải',
  'globals.date': 'Ngày',
  'globals.searchPlaceholder': 'Chọn mục…',
  'globals.addProductEan': 'Mã sản phẩm EAN',
  'globals.textBoxSearch': 'Tìm kiếm',
  'globals.delete': 'Xoá',
  'globals.return': 'Quay lại',
  'globals.client': 'Khách hàng',
  'globals.Remove': 'Bỏ ',
  'globals.RemoveProducts': 'Bỏ sản phẩm',
  'globals.searchProducts': 'Tìm kiếm theo sản phẩm',
  'globals.confirmRemoveProducts': 'Bạn muốn bỏ sản phẩm này ra khỏi đơn hàng?',
  'globals.searchProductsInput': 'Tìm kiếm sản phẩm',
  'globals.departament': 'Ngành hàng',
  'globals.Save': 'Lưu',
  'globals.searchBy': 'Tìm kiếm theo',
  'globals.id': 'Mã đơn hàng',
  'globals.required': 'Thông tin cần điền',
  'globals.allRequired': 'Các thông tin cần điền',
  'globals.spanish': '🇨🇴 Tiếng Tây Ban Nha',
  'globals.english': '🇺🇸 Tiếng Anh',
  'globals.vietnamita': '🇻🇳 Tiếng Việt',
  'globals.errorNotification': 'Hệ thống có lỗi',
  'globals.finished': 'Hoàn tất',
  'globals.Detail': 'Chi tiết',
  'globals.coverage': 'Khu vực đang hoạt động',
  'globals.orders': 'Đơn hàng',
  'globals.stops': 'Điểm giao hàng',
  'globals.download': 'Tải về bằng file Excel',
  'globals.downloadLogInventory': 'Tải hàng tồn bằng file Excel',
  'globals.orderID': 'Mã đơn hàng',
  'globals.userID': 'Tên tài khoản',
  'globals.options': 'Lựa chọn',
  'globals.mudules': 'Danh mục Admin',
  'globals.Localidad': 'Quận',
  'globals.searchOrder': 'Tìm kiếm theo mã số đơn hàng',
  'globals.errorService': 'Đã có lỗi xảy ra, vui lòng thử lại…',
  'globals.neighborhood': 'Phường',
  'globals.ally': 'Hẻm',
  'globals.shipping': 'Phí vận chuyển',
  'globals.quqoStore': 'Cửa hàng',
  'globals.aprobar': 'Duyệt',
  'globals.ver': 'Kiểm tra',
  'globals.complete': 'Hoàn tất',
  'globals.config': 'Thiết lập',
  'globals.show': 'Kiểm tra',
  'globals.number': 'Số',
  'globals.discount': 'Chiết khấu',
  'globals.detailTC': 'Thông tin thẻ tín dụng',
  'globals.NoPermissions': 'Không được phép',
  'globals.commission': 'Phí dịch vụ',
  'globals.changeProvider': 'Đổi Công ty',
  'globals.changetype': 'Thay đổi (Cửa hàng - Q2C)',
  'globals.changeOrderStatus': 'Thay đổi trạng thái đơn hàng',
  'globals.later': 'Một lát sau',
  'globals.noImage': 'Sản phẩm này không có hình ảnh',
  'globals.confirm': 'Xác nhận',
  'globals.confirmDownload': 'Xác nhận tải về',
  'globals.screen': 'Màn hình',
  'globals.help': 'Giúp đỡ',
  'globals.units': 'Đơn vị',
  'globals.receive': 'Nhận',
  'globals.seeUser': 'Kiểm tra tài khoản',
  'globals.changeProvidersByCountryUser': 'Hiển thị nhà cung từ quốc gia của tài khoản',
  'globals.startDate': 'Ngày bắt đầu',
  'globals.endTime': 'ngày cuối',
  'globals.selectDate': 'chọn ngày',
  'globals.reset': 'cài lại',
  'globals.downloadText': 'Tải xuống đơn đặt hàng',
  'globals.updated': 'cập nhật',
  'globals.willUpdate': 'Sẽ cập nhật',
  'orders.order': 'Đơn đặt hàng',
  'orders.title': 'Danh sách đơn hàng',
  'orders.subtitle': 'Bảng đơn hàng Quqo',
  'orders.filterByStatus': 'Lọc theo tình trạng đơn hàng',
  'orders.downloadPdf': 'Gửi file PDF theo ngày',
  'orders.downloadExcel': 'Tải về bằng file Excel',
  'orders.downloadExcelConfirm': 'Bạn  muốn nhận đơn hàng file Excel qua email?',
  'orders.sendPDF': 'Gửi đơn hàng file pdf',
  'orders.selectDate': 'Chọn ngày',
  'orders.required.EAN': 'Vui lòng nhập EAN của sản phẩm!',
  'orders.required.email': 'Vui lòng nhập địa chỉ email ',
  'orders.neighborhood': 'Khu vực lân cận',
  'orders.map': 'Xem bản đồ',
  'orders.statusStore': 'Chi tiết cửa hàng',
  'orders.cash': 'Tiền mặt',
  'orders.zalopay': 'ZaloPay',
  'orders.linkToPay': 'Liên kết để thanh toán',
  'orders.tc': 'Thẻ tín dụng',
  'orders.noPaymentMethod': 'Không có phương thức thanh toán',
  'orders.OrderTotal': 'Chi tiết thanh toán',
  'orders.TotalQuantity': 'Tổng giá trị đơn hàng',
  'orders.PaidwithQuota': 'Tổng số phải thanh toán',
  'orders.PaidwithPoints': 'Được thanh toán bằng điểm',
  'orders.PointsUsed': 'Số điểm đã sử dụng',
  'orders.selectDateRequired': 'Bạn phải chọn ngày để tiếp tục …',
  'orders.OrderDate': 'Ngày đặt hàng',
  'orders.PaymentMethod': 'Phương thức thanh toán',
  'orders.files': 'Trong một vài phút, bạn sẽ nhận được email với các tập tin.',
  'orders.DiscountProducts': 'Sản phẩm được chiết khấu thành công',
  'orders.discountOK': 'Các sản phẩm của đơn đặt hàng đã được giảm giá',
  'orders.OrderID': 'Mã đơn hàng',
  'orders.SelectRes': 'Chọn người phụ trách',
  'orders.totalOrders': 'Tổng đơn hàng',
  'orders.SelectTheReason': 'Chọn lý do',
  'orders.promisedDate': 'Ngày nhận hàng',
  'orders.insuffucientPoints': 'Không đủ điểm',
  'orders.insuffucientPointsSub': 'Đơn hàng đã bị hủy còn điểm, hiện tại khách hàng không đủ điểm để thay đổi trạng thái đơn hàng',
  'orders.textRequired': 'Bạn phải chọn một lý do',
  'orders.add': 'Thêm vào',
  'orders.replace': 'Thay thế',
  'orders.configuration': 'Thiết lập',
  'orders.noConfiguration': 'Không được thiết lập',
  'orders.delivery': 'Giao hàng',
  'orders.promise': 'Hứa',
  'orders.discountByCoupon': 'Phiếu giảm giá',
  'orders.discounts': 'Chiết khấu',
  'orders.pendingReview': 'Đang chờ xem xét',
  'orders.approved': 'Đã được duyệt',
  'orders.declined': 'Từ chối',
  'orders.reschedule': 'Chọn lại ngày nhận hàng',
  'orders.rescheduleSub': 'Bạn muốn chọn lại ngày nhận hàng không?',
  'orders.rescheduleQuestion': 'Bạn muốn đổi ngày nhận hàng vì',
  'orders.changeStatusOrderConform': 'Bạn muốn đánh dấu đơn đặt hàng này là đã giao không?',
  'orders.changeStatusOrderTransit': 'Bạn muốn đổi sang trạng thái đang giao hàng?',
  'orders.allowCancelOrders': 'Cho phép hủy đơn đặt hàng',
  'orders.changeOrdersToTransit': 'Đổi sang trạng thái đang giao hàng',
  'orders.markOrderAsDelivered': 'Đánh dấu đơn hàng là đã giao',
  'orders.markOrderInTransit': 'Đánh dấu đang giao ',
  'orders.changeStatusAll': 'Thay đổi trạng thái cho các đơn đặt hàng đã chọn',
  'orders.dateRange': 'Lọc theo ngày giao hàng',
  'orders.numberInoviceMessage': 'Lưu số hoá đơn',
  'orders.numberInovice': 'Hoá đơn',
  'orders.enterInoviceNumber': 'Nhập số hoá đơn',
  'orders.successSaveInoviceNumber': 'Số hoá đơn được lưu thành công',
  'orders.seeOrdersAllProviders': 'xem đơn đặt hàng của tất cả các nhà cung cấp',
  'orders.paymentMethod.CREDIT_CARD': 'Thẻ tín dụng',
  'orders.paymentMethod.LINKTOPAY': 'Link To Pay',
  'orders.paymentMethod.CASH': 'Tiền mặt',
  'orders.paymentMethod.ZALOPAY': 'ZaloPay',
  'orders.createOrder': 'Tạo đơn hàng',
  'orders.actualStatus': 'Thực trạng',
  'orders.newStatus': 'Trạng thái mới',
  'orders.results': 'Các kết quả',
  'orders.purchaseMadeByWhatsapp': 'Giao dịch mua do bot thực hiện từ whatsapp',
  'orders.purchaseMadeByWhatsappType1': 'Hình thức mua hàng: Giao hàng tận nhà',
  'orders.purchaseMadeByWhatsappType2': 'Hình thức mua: Mua và Thu thập',
  'orders.DontCollectMoney': "Bạn không cần thu tiền của đơn hàng này.",

  'ordenDetail.title': 'Mục Đặt hàng',
  'ordenDetail.ReasonCancellation': 'Lý do hủy',
  'ordenDetail.ReasonReview': 'Lý do xem xét',
  'ordenDetail.transit': 'Nhân viên vận chuyển đang đến địa điểm của bạn',

  'ordersItems.title': 'Chi tiết đơn hàng',
  'ordersItems.items': 'Sản phẩm',
  'ordersItems.message': 'Sản phẩm đã được xoá, vui lòng lưu các thay đổi để đơn hàng được chính xác.',
  'ordersItems.messageError': 'Bạn chưa chọn sản phẩm',
  'ordersItems.confirmDelete': 'Bạn có chắc muốn xoá sản phẩm này?',
  'ordersItems.toItemsDelete': 'Các sản phẩm xoá',
  'ordersItems.confirm1': 'Bạn có chắc muốn cập nhật đơn hàng?',
  'ordersItems.toSaveChanges': 'Chỉ lưu các thay đổi',
  'ordersItems.confirm2': 'bạn có chắc rằng mình muốn cập nhật và xác nhận đơn hàng?',
  'ordersItems.toSaveChangesAndDelivery': 'Lưu các thay đổi và đánh dấu đã giao hàng',
  'ordersItems.messageinf': 'Số tiền không thể lớn hơn đơn hàng hiện hữu',
  'ordersItems.mensageSuccess': 'Thay đổi thành công',
  'ordersItems.mensageSuccess2': 'Đã chỉnh sửa thông tin',
  'ordersItems.loadingDateChange': 'Tải thông tin thay đổi',
  'ordersItems.noChanges': 'Không thay đổi',
  'ordersItems.changeDateDelivery': 'Thay đổi ngày giao hàng',
  'ordersItems.GenetatePDF': 'Tổng hợp đơn hàng bằng file pdf',
  'ordersItems.GenetateZIP': 'Tổng hợp đơn hàng bằng file zip',
  'ordersItems.statusReasonToCancel': 'Cửa hàng đóng cửa/Không thể liên hệ được khách hàng',
  'ordersItems.statusReasonToCancel2': 'Đơn hàng chưa được đặt',
  'ordersItems.statusReasonToCancel3': 'Không có tiền mặt',
  'ordersItems.statusReasonToCancel4': 'Đơn hàng bị trùng',
  'ordersItems.statusReasonToCancel5': 'Chất lượng sản phẩm',
  'ordersItems.statusReasonToCancel6': 'Sai hàng hoá',
  'ordersItems.statusReasonToCancel7': 'Giao hàng trễ',
  'ordersItems.statusReasonToCancel8': 'Không thuộc khu vực giao hàng',
  'ordersItems.statusReasonToCancel9': 'Thiếu hàng',
  'ordersItems.statusReasonToCancel10': 'Sai địa chỉ',
  'ordersItems.statusReasonToCancel11': 'Thử',
  'ordersItems.statusReasonToCancel12': 'Chủ cửa hàng',
  'ordersItems.confirmCancel': 'Bạn có chắn chắn muốn huỷ đơn hàng?',
  'ordersItems.cancelOrden': 'Huỷ đơn hàng',
  'ordersItems.sendEmailOrden': 'Bạn muốn gửi đơn hàng qua email?',
  'ordersItems.sendEmailOrder': 'Bạn muốn gửi đơn hàng qua email?',
  'ordersItems.downloadOrderPDF': 'Bạn muốn tải đơn hàng bằng file pdf?',
  'ordersItems.downloadOrderZIP': 'Bạn muốn tải đơn hàng bằng file zip?',
  'ordersItems.downloadPDF': 'Tải file pdf',
  'ordersItems.downloadZIP': 'tải file zip',
  'OrdersItems.changeStatus': 'Đang tải quá trình thay đổi tình trạng đơn hàng',
  'OrdersItems.sameState': 'Tình trạng đơn hàng như cũ',
  'ordersItems.changeStatusOrder': 'Thay đổi tình trạng đơn hàng',
  'ordersItems.selectState': 'Chọn tình trạng đơn hàng',
  'ordersItems.changeStatusOrderReview': 'Thay đổi trạng tháí',
  'ordersItems.editQuantity': 'Chỉnh sửa số lượng ',
  'ordersItems.changeQuantity': 'Thay đổi số lượng',
  'ordersItems.name': 'Tên sản phẩm',
  'ordersItems.pickerModal': 'Chọn đơn hàng muốn huỷ sản phẩm',
  'ordersItems.searchOrders': 'Tìm kiếm đơn hàng',
  'ordersItems.status': 'Tình trạng đơn hàng',
  'ordersItems.removeProducts': 'Lấy sản phẩm ra',
  'ordersItems.mensageError': 'Sản phẩm không có trong đơn hàng',
  'ordersItems.mensageLoading': 'Giảm trừ sản phẩm trong đơn hàng',
  'ordersItems.DeliveryDateUpdated': 'Cập nhật ngày giao hàng',
  'ordersItems.pdf': 'Đơn hàng đã được tải xuống bằng file pdf',
  'ordersItems.zip': 'Đã tải file zip',
  'ordersItems.sendEmail': 'Email đã được gửi',
  'ordersItems.updateStatus': 'Cập nhật thành công tình trạng đơn hàng',
  'ordersItems.inventoryOrders': 'Số lượng tồn kho',
  'ordersItems.messageRevisionDefault': 'Bạn không thể nhận thông báo giao hàng',
  'ordersItems.remplaceProductTitle': 'Thay thế sản phẩm',
  'ordersItems.remplaceProductDescription': 'Chọn sản phẩm muốn thay thế',

  'promotions.promotion': 'Khuyến mãi',
  'promotions.title': 'Danh sách khuyến mãi',
  'promotions.subtitle': 'Bảng khuyến mãi của Quqo',
  'promotions.all': 'Tất cả',
  'promotions.acitive': 'Đang hoạt động',
  'promotions.inactive': 'Đang tạm ngưng',
  'promotions.textBox': 'Lọc theo tên',
  'promotions.filter': 'Danh sách lọc',
  'promotions.newPromotion': 'Tạo quảng cáo',
  'promotions.date': 'Ngày khởi tạo',
  'promotions.UpdateDate': 'Ngày cập nhật',
  'promotions.quantityCurrent': 'Số lượng hiện tạị',
  'promotions.titleForm': 'Mẫu khuyến mãi',
  'promotions.promoCode': 'Mã số khuyến mãi',
  'promotions.promoCodeRequired': 'Vui lòng nhập mã số khuyến mãi',
  'promotions.public': 'Mở',
  'promotions.Limit': 'Giới hạn?',
  'promotions.totalQuantity': 'Tổng số lượng',
  'promotions.totalQuantityRequired': 'Nhập số lượng khuyến mãi ',
  'promotions.totalUser': 'Số lượng khuyến mãi mỗi tài khoản',
  'promotions.totalUserRequired': 'Nhập số lượng khuyến mãi ',
  'promotions.productSelect': 'Chọn sản phẩm',
  'promotions.productSelectRequired': 'Vui lòng chọn sản phẩm',
  'promotions.normalPrice': 'Giá thường',
  'promotions.promotionPrice': 'Giá khuyến mãi',
  'promotions.imageChange': 'Đổi hình',
  'promotions.textPromotionSave': 'Để thêm hình, bạn phải lưu chương trình khuyến mãi trước',
  'promotions.Save': 'Lưu',
  'promotions.sendNotifications': 'Gửi thông báo',
  'promotions.sendNotificationsOK': 'Gửi thông báo khuyến mãi',
  'promotions.alertSend': 'Thông báo đã được gửi',
  'promotions.requireName': 'Vui lòng điền tên chương trình khuyến mãi',
  'promotions.savePromo': 'Chương trình khuyến mãi mới đã được lưu',
  'promotions.updatePromo': 'Chương trình khuyến mãi đã được cập nhật',
  'promotions.ChangeStatus': 'Tình trạng khuyến mãi thay đổi thành công',
  'promotions.selectType': 'Chọn loại hình',
  'promotions.none': 'Không áp dụng',
  'promotions.product': 'Sản phẩm',
  'promotions.departament': 'Ngành hàng',
  'promotions.category': 'Danh mục',
  'promotions.combined': 'Gộp sản phẩm',
  'promotions.atLeastOne': 'Bản khuyến mãi nhiều sản phẩm',
  'promotions.minAmountListProduct': 'Số lượng tối thiểu cần đặt ',
  'promotions.createPrmotions': 'Lưu chương trình khuyến mãi',
  'promotions.addProduct': 'Thêm sản phẩm',
  'promotions.addPrize': 'Thêm giá sản phẩm',
  'promotions.confirmDelete': 'Bạn có chắc muốn lấy sản phẩm này ra?',
  'promotions.confirmNoPrize': 'Bạn không muốn tạo phần thưởng?',
  'promotions.noDelete': 'Phải có ít nhất 1 sản phẩm',
  'promotions.tipePrize': 'Loại khuyến mãi',
  'promotions.discount': 'Chiết khấu',
  'promotions.modalConfirm': 'Bạn muốn lưu chương trình khuyến mãi này?',
  'promotions.quantity+': 'Số lượng tối đa mỗi khách hàng',
  'promotions.quantity-': 'Số lượng cần thiết',
  'promotions.distributeDiscount': 'Bạn phải thiết lập chiết khấu trên sản phẩm',
  'promotions.successImage': 'Hình chiết khấu được lưu thành công',
  'promotions.flashType': 'Loại hình khuyến mãi nhanh',
  'promotions.flashType0': 'Không có khuyến mãi nhanh',
  'promotions.flashType1': 'Theo số lượng',
  'promotions.flashType2': 'Theo thời gian',
  'promotions.selectDate': 'Chọn ngày và giờ',
  'promotions.messageErrorDate': 'Chọn ngày và giờ kết thúc',
  'promotions.messageConfigProduct': 'Không thể tạo khuyến mãi cho các sản phẩm gộp, vui lòng chọn sản phẩm khác',
  'promotions.showIn': 'Hiển thị:',
  'promotions.minimumAmount': 'Số lượng tối thiểu',
  'promotions.promotion1': 'Khuyến mãi',
  'promotions.errorPromotoType5': 'Không thể tạo khuyến mãi cho các sản phẩm gộp',
  'promotions.maximumSaleQuantity': 'Số lượng khuyến mãi tối đa',

  'specialPromotion.buttonText': 'Tạo khuyến mãi đặc biệt',
  'specialPromotion.listProducts': 'Danh sách sản phẩm',
  'specialPromotion.selector': 'loại khuyến mãi',
  'specialPromotion.type_selector': 'bộ chọn loại',
  'specialPromotion.max_discount_by_user': 'Giảm giá tối đa cho mỗi người dùng',
  'specialPromotion.min_amount': 'Số tiền tối thiểu',
  'specialPromotion.type_gift': 'Loại giải thưởng',
  'specialPromotion.gift_value': 'Giá trị giải thưởng',
  'specialPromotion.gift_coupons': 'Phiếu giảm giá',
  'specialPromotion.rules': 'Thêm quy tắc',
  'specialPromotion.PROMOTION_PRIZE_PRODUCT': 'Sản phẩm',
  'specialPromotion.PROMOTION_PRIZE_DISCOUNT': 'Giảm giá',
  'specialPromotion.PROMOTION_PRIZE_ROULETTE': 'Cò quay',
  'specialPromotion.PROMOTION_PRIZE_COUPON': 'Phiếu giảm giá',
  'specialPromotion.PROMOTION_PRIZE_CASHBACK': 'Hoàn tiền',
  'specialPromotion.PROMOTION_PRIZE_PERCENT': 'Phần trăm',
  'specialPromotion.successCreatePromo': 'Khuyến mãi đặc biệt đã được tạo thành công.',

  'products.product': 'Sản phẩm',
  'products.title': 'Danh sách sản phẩm',
  'products.subtitle': 'Thông tin sản phẩm Quqo',
  'products.textBox': 'Lọc theo tên',
  'products.promo': 'Khuyến mãi',
  'products.priceCurrent': 'Giá hiện tại',
  'products.EAN': 'Sản phẩm',
  'products.productInPromo': 'Sản phẩm đang khuyến mãi',
  'products.editProduct': 'Chỉnh sửa thông tin sản phẩm',
  'products.requiredName': 'Vui lòng thêm tên sản phẩm',
  'products.requiredDescription': 'Vui lòng mô tả sản phẩm',
  'products.requiredQunatity': 'Nhập số lượng',
  'products.requiredPrice': 'Vui lòng mô tả sản phẩm',
  'products.brand': 'Thương hiệu',
  'products.categories': 'Ngành hàng',
  'products.changeImage': 'Đổi hình',
  'products.addImage': 'Thêm hình ảnh',
  'products.requiredDepartament': 'Thiếu ngành hàng',
  'products.brandDepartament': 'Thiếu tên thương hiệu',
  'products.categoryDepartament': 'Thiếu tên thương hiệu',
  'products.featured': 'Ưu tiên',
  'products.level': 'Mức ưu tiên',
  'products.updateLoad': 'Sản phẩm đang cập nhật',
  'products.updateSuccess': 'Cập nhật sản phẩm thành công',
  'Products.confirm': 'Bạn muốn cập nhật sản phẩm?',
  'Products.confirmCancel': 'Bạn muốn huỷ các thay đổi?',
  'Products.descuento': 'Giá có chiết khấu',
  'Products.descuentoMessage': 'Giá chiết khấu không được lớn hơn giá cơ sở.',
  'Products.max': 'Số lượng tối đa cho mỗi khách hàng',
  'Products.updatedProduct': 'Sản phẩm đã được cập nhật',
  'Products.createProduct': 'Sản phẩm đã được thêm vào danh mục sản phẩm',
  'Products.manufactured': 'Nhà sản xuất',
  'Products.DownloadCatalog': 'Tải Danh mục sản phẩm',
  'Products.successChangeStatus': 'Thay đổi thành công',
  'Products.packaging': 'Đóng gói',
  'Products.PackagingUnit': 'Đơn vị đóng gói',
  'Products.MinimunUnitSell': 'Số lượng sản phẩm tối thiểu. (-1) không giới hạn',
  'Products.productsUpdate': 'Cập nhật sản phẩm',
  'Products.addConfigByq2c': 'Thông số này sẽ được thêm vào danh mục sản phẩm Q2C',
  'Products.toBlock': 'Khoá sản phẩm',
  'Products.tax': 'Thuế sản phẩm',
  'Products.taxPercent': 'Phần trăm thuế',
  'Products.amountTax': 'Thuế',
  'Products.uploadFile': 'Tải file lên',
  'Products.downloadFile': 'Tải file về',
  'Products.fileToBill': 'Tải file thuế sản phẩm lên',
  'Products.presentation': 'Đóng gói',
  'products.minQuantity': 'Số lượng tối thiểu mỗi đơn hàng. 0 Không có số lượng tối thiểu',
  'products.editPrice': 'Chỉnh sửa giá',
  'products.downloadByEmail': 'Danh mục sẽ được gửi qua email sau khoảng 3 phút.',
  'products.scales': 'Quy mô',
  'products.confirmDelete': 'Bạn có chắc chắn xóa hình ảnh đã chọn không?',
  'products.confirmDeleteSuccess': 'Đã xóa hình ảnh thành công',
  'products.confirmAddSuccess': 'Hình ảnh đã được thêm thành công',

  'product.scales.title': 'Thang giá cho sản phẩm',
  'product.scales.min_quantity': 'Số lượng tối thiểu',
  'product.scales.confirmSave': 'Bạn có chắc chắn để lưu các quy mô?',
  'product.scales.save': 'Lưu thay đổi',
  'product.scales.addScale': 'thêm quy mô',
  'product.scales.messageSuccess': 'Cân đã được lưu thành công..',
  'product.scales.errorModal1': 'Sản phẩm này có các thang giá. Không thể thêm cấu hình.',
  'product.scales.errorModal2': 'Sản phẩm này có cài đặt. Không thể thêm thang giá, để thêm thang bạn phải tắt tất cả các nhóm cấu hình hiện có.',
  'product.scales.deleteSuccess': 'Đã loại bỏ quy mô thành công',
  'product.scales.percentage': 'Phần trăm',
  'product.scales.absolute': 'tuyệt đối',
  'product.scales.finalPrice': 'Giá niêm yết',
  'product.scales.type1': 'Cân theo giá',
  'product.scales.type2': 'Tỷ lệ phần trăm',
  'product.scales.type3': 'Số tiền',
  'product.createScales': 'Tạo Cân',
  'product.modifyScales': 'Sửa đổi quy mô',

  'group.title': 'Nhóm',
  'group.addConfig': 'Thêm thông tin sản phẩm',
  'group.variant': 'Loại hình',
  'group.addition': 'Thêm',
  'group.modifier': 'Chỉnh sửa',
  'group.minConfiguration': 'Số lượng tối thiểu',
  'group.maxConfiguration': 'Số lượng tối đa',
  'group.Required': 'Thông tin không thể bỏ trống',
  'group.createGroup': 'Tạo nhóm',
  'group.updateGroup': 'Cập nhật nhóm',
  'group.newGroup': 'Thêm nhóm mới',
  'group.config': 'Cài đặt',
  'group.extraPrice': 'Giá',
  'group.is_default': 'Mặc định',
  'group.baseProduct': 'Sản phẩm ',
  'group.extraPriceType': 'Giá mới',
  'group.changePrice': 'Cập nhật giá',
  'group.messageSuccess': 'Thông tin cài đặt nhóm được cập nhật',
  'group.messageDelete': 'Thông tin cài đặt đã được gỡ',
  'group.messageSuccessGroup': 'Nhóm được tạo thành công',
  'group.messageUpdateGroup': 'Nhóm được cập nhật thành công',
  'group.questionCreateGroup': 'Bạn có chắc muốn tạo nhóm?',
  'group.questionUpdateGroup': 'Bạn có chắc muốn cập nhật nhóm?',
  'group.deleteGroup': 'Xoá nhóm',
  'group.deleteGroupConfirm': 'Bạn chắc chắn muốn xoá nhóm?',
  'group.noDeleteGroup': 'Bạn phải gỡ bỏ các cài đặt trước đây',
  'group.youHave': 'Bạn có',
  'group.specialPrice': 'Giá đặc biệt',
  'group.messageProductBase': 'Sản phẩm chính phải được thiết lập',

  'ProductList.Title': 'Danh mục sản phẩm',
  'ProductList.TitleList': 'Danh sách danh mục sản phẩm',
  'ProductList.Confirm': 'Bạn muốn cập nhật sản phẩm?',
  'ProductList.OK': 'Sản phẩm được cập nhật thành công',
  'ProductList.form': 'Mẫu sản phẩm',

  'catalogUpdate.cataUpdate': 'Cập nhật danh mục sản phẩm',
  'catalogUpdate.cataUpdate1': 'Cập nhật danh mục sản phẩm',
  'catalogUpdate.cataUpdate2': 'Danh mục sản phẩm Q2C',
  'catalogUpdate.title': 'Nhà cung ứng',
  'catalogUpdate.subtitle': 'Chọn nhà cung ứng muốn cập nhật danh mục sản phẩm',
  'catalogUpdate.subtitleQ2C': 'Cập nhật và tải về danh mục sản phẩm để thể hiện trong ứng dụng Q2C',
  'catalogUpdate.viewModel': 'File mẫu',
  'catalogUpdate.productsTotal': 'Tổng số lượng sản phẩm',
  'catalogUpdate.boxUpdateCatalog2': 'Xác thực danh mục sản phẩm được cập nhật là chính xác',
  'catalogUpdate.dragFile': 'Chọn hoặc kéo file đến mục này để tải lên',
  'catalogUpdate.boxDragFile': 'Bạn chắc chắn muốn cập nhật Danh mục sản phẩm Quqo?',
  'catalogUpdate.boxDragFile2': 'Số lượng sản phẩm trong file CSV là',
  'catalogUpdate.boxDragFileLoading': 'Đang tải danh mục sản phẩm',
  'catalogUpdate.boxDragFileLoading2': 'Chức năng này có thể mất khoảng 5-15 phút',
  'catalogUpdate.boxDragFileLoading3': 'Có',
  'catalogUpdate.text': 'Chức năng này có thể mất khoảng 5-15 phút',
  'catalogUpdate.fileSuccess': 'File được tải lên thành công',
  'catalogUpdate.fileError': 'File chưa được tải lên',
  'catalogUpdate.productsTo': 'Sản phẩm Quqo',

  'users.infoStores': 'Thông tin cửa hàng',
  'users.user': 'Tài khoản',
  'users.title': 'Tài khoản',
  'users.subtitle': 'Tài khoản của Quqo',
  'users.listUsers': 'Danh sách tài khoản',
  'users.whitelist': 'Tài khoản tốt',
  'users.search': 'Tìm kiếm',
  'users.role.store': 'Cửa hàng',
  'users.role.salesman': 'Người bán',
  'users.role.pilot': 'Nhân viên giao hàng',
  'users.role.admin_warehouse': 'Admin kho',
  'users.role.warehouse': 'Kho',
  'users.role.custom_role': 'Vai trò cá nhân',
  'users.status.infoStatus1': 'Không hoạt động',
  'users.status.infoStatus2': 'Kích hoạt',
  'users.status.infoStatus3': 'Không đúng/chưa kích hoạt',
  'users.status.infoStatus4': 'Đã khoá',
  'users.status.infoStatus1s': 'Không hoạt động',
  'users.status.infoStatus2s': 'Kích hoạt',
  'users.status.infoStatus3s': 'Không đúng/chưa kích hoạt',
  'users.status.infoStatus4s': 'Đã khoá',
  'users.changeCode1': 'Mã số kích hoạt khách hàng',
  'users.changeCode2': 'Tạo mã số nhân viên kinh doanh',
  'users.code': 'Tạo mã số nhân viên kinh doanh',
  'users.actualCode': 'Mã số hiện tại',
  'users.changeCodeComercialTextBox': 'Nhập mã số',
  'users.referralCode': 'Thay đổi mã người bán',
  'users.comercialCode': 'Thay đổi mã kích hoạt',
  'users.noComercial': 'Không tồn tại',
  'users.noStore': 'Không phải cửa hàng',
  'users.infoCancel': 'Bạn muốn huỷ?',
  'users.IDComercial': 'Mã số nhân viên kinh doanh',
  'users.restorePassword': 'Đặt lại mật khẩu',
  'users.inSendEmail': 'Bạn muốn gửi email đến khách hàng?',
  'users.sendEmail': 'Gửi email',
  'users.infoUsers': 'Thông tin tài khoản',
  'users.changeCodeComercial': 'Nhập mã số nhân viên kinh doanh',
  'users.changeCodeSalesman': 'Đổi mã số nhân viên kinh doanh',
  'users.updaterol': 'Vai trò đã được thay đổi',
  'users.updateStatus': 'Tình trạng của tài khoản đã thay đổi thành công',
  'users.updateCode': 'Thay đổi thành công mã số nhân viên kinh doanh',
  'users.referralUser': 'Thay đổi thành công mã số nhân viên kinh doanh',
  'users.sendEmail2': 'Email đã được gửi',
  'users.updateltlg': 'Kinh độ và vĩ độ',
  'users.updateUsers': 'Tài khoản đã được cập nhật',
  'users.okSave': 'Bạn muốn cập nhật tài khoản?',
  'users.uploadFileExito': 'Cargar usuarios de misurtii',
  'users.confirmTextUploadFile': 'Seguro de cargar el archivo XLSX?',
  'users.uploadFile': 'Subir archivo .xlsx',

  'user.address_references': 'Dấu hiệu chung quanh',
  'user.editUsers': 'Mẫu chỉnh sửa tài khoản',
  'user.country_code': 'Mã quốc gia',
  'user.doesNotHave': 'Không tồn tại',
  'user.doesMotApply': 'Không áp dụng',
  'user.userValidation': 'Xác định tài khoản',
  'user.detailStore': 'Chi tiết cửa hàng',
  'user.company': 'Công ty',

  'users.changeToStore': 'Chuyển sang cửa hàng',
  'users.nameStore': 'Tên cửa hàng',
  'users.documentType': 'Loại hồ sơ',
  'users.details': 'Thông tin chi tiết',
  'users.general': 'Thông tin tài khoản',
  'users.changeLocation': 'Đổi vị trí',
  'users.successCreateStore': 'Chuyển cửa hàng thành công',
  'users.questionCreateStore': 'Bạn muốn đổi vị trí tài khoản sang Cửa hàng?',
  'users.updatePermissions': 'Sửa đổi quyền quản trị viên',
  'users.updatePermissionsqrew': 'Sửa đổi quyền trong Qrew',
  'users.changePermissionsText': 'Bạn muốn thay đổi quyền của',
  'users.changePermissionsSuccess': 'Quyền của người dùng đã được cập nhật thành công ',
  'users.qrew.PROSPECTS_SECTION': 'Cửa hàng tiềm năng',
  'users.qrew.DELIVERY_ORDERS_SECTION': 'Đơn hàng cần giao',
  'users.qrew.NEAR_STORES_SECTION': 'Cửa hàng gần nhất',
  'users.qrew.CONCILIATE_ORDERS_SECTION': 'Thống nhất đơn hàng',
  'users.qrew.VISIT_SECTION': 'Cửa hàng cần ghé',
  'users.qrew.SUPPORT_SECTION': 'Hỗ trợ',
  'users.qrew.PICKER_SECTION': 'Nhân viên soạn hàng',
  'users.qrew.WAREHOUSE_INVENTORY_SECTION': 'Hàng tồn',
  'users.discontinued': 'Đình chỉ',
  'users.discontinuedDate1': 'Đình chỉ từ',
  'users.discontinuedDate2': 'Đình chỉ cho đến',
  'users.mobilePhone2': 'Số liên lạc phụ',
  'users.country_code2': 'Mã số liên lạc phụ',
  'users.contactNumberError': 'Số liên lạc không thể trùng với số của tài khoản',
  'users.addUserWhiteList': 'Thêm tài khoản vào danh sách',
  'users.searchUser': 'Tìm kiếm tài khoản',
  'users.alertToWhiteListUser': 'Xác nhận',
  'users.untilDate1': 'Từ ngày',
  'users.untilDate': 'Đến ngày',
  'users.okTextModal': 'Gửi đến danh sách',
  'users.successWhiteList': 'Tài khoản được thêm vào danh sách',
  'users.successUpdateWhiteList': 'Tài khoản được cập nhật thành công',
  'users.successDeleteWhiteList': 'Tài khoản được loại ra khỏi danh sách thành công',
  'users.QuestionDeleteWhiteList': 'Bạn muốn loại tài khoản này ra khỏi danh sách? ',
  'users.currentDate': 'Ngày hiện tại',
  'users.changeDate': 'Đổi ngày',
  'users.location': 'Địa điểm',
  'users.totalOrdersCancels': 'Huỷ đơn hàng',
  'users.totalOrdersDelivered': 'Đơn hàng đã được giao',
  'users.returnOfProducts': 'Trả hàng',
  'users.totalOrders': 'Tổng đơn hàng',
  'users.userType': 'Loại tài khoản',
  'users.statusUser': 'Tình trạng tài khoản',
  'users.suspendedClient': 'Khách hàng bị cấm sử dụng ',
  'users.activate': 'Kích hoạt',
  'users.coordinator': 'Danh sách giám sát',
  'users.coordinatorCreate': 'Tạo tài khoản giám sát',
  'users.coordinatorCreateSuccesss': 'Tài khoản giám sát được tạo thành công',
  'users.inheritedProvidersUsers': 'Tạo tài khoản mới cho nhà cung phụ',
  'users.inheritedProvidersUsersCreate': 'Tạo tài khoản',
  'users.usersWithProviderCoverage': 'Người dùng có phạm vi bảo hiểm của nhà cung cấp',

  'trackingComercial.tracking': 'Kiểm tra tuyến đường nhân viên kinh doanh',
  'trackingComercial.searchComercial': 'Tìm kiếm nhân viên kinh doanh',

  'activations.CommercialActivations': 'Kích hoạt tài khoản nhân viên kinh doanh',
  'activations.search': 'Tìm kiếm nhân viên kinh doanh',
  'activations.noUsers': 'Không có tài khoản',
  'activations.noName': 'Không có tên',
  'activations.NoData': 'Không có dữ liệu',

  'Missions.mission': 'Nhiệm vụ',
  'Missions.mission1': 'Nhiệm vụ',
  'Missions.missionDashboard': 'Bảng thông tin',
  'Missions.poitnsEarned': 'Số điểm có ',
  'Missions.poitnsUsed': 'Số điểm đã sử dụng',
  'Missions.bottom': '10 phản hồi trễ nhất',
  'Missions.top': '10 phản hồi sớm nhất',
  'Missions.position': 'Vị trí câu hỏi',
  'Missions.effectiveness': 'Hiệu quả',
  'Missions.misSubTittle': 'Bảng nhiệm vụ',
  'Missions.misSearch': 'Lọc theo loại nhiệm vụ',
  'Missions.misSelectTipe': 'Chọn loại nhiệm vụ',
  'Missions.misSelectTipe1': 'Bảng câu hỏi',
  'Missions.misSelectTipe2': 'Mẫu',
  'Missions.misSelectTipe3': 'Bảng câu hỏi',
  'Missions.misSelectTipe4': 'Hình sản phẩm trong cửa hàng',
  'Missions.misFilterRangeDate': 'Lọc theo ngày',
  'Missions.misFilterRangeDate0': '7 ngày gần đây',
  'Missions.misFilterRangeDate1': '8 ngày gần đây',
  'Missions.misFilterRangeDate2': '30 ngày gần đây',
  'Missions.misFilterRangeDate3': '60 ngày gần đây',
  'Missions.misFilterRangeDate4': '90 ngày gần đây',
  'Missions.misFilterRangeDate5': '180 ngày gần đây',
  'Missions.misFilterRangeDate6': 'Năm trước',
  'Missions.misFilterRangeDate7': 'Tháng hiện tại',
  'Missions.misFilterRangeDate8': 'Năm hiện tại',
  'Missions.misFilterRangeDate9': 'Tuỳ chọn',
  'Missions.misFilterBudget': 'Ngân sách',
  'Missions.misFilterEarnedPoints': 'Số điểm có ',
  'Missions.misFilterBudgetThat': 'Ngân sách còn lại',
  'Missions.misFilterDate': 'Ngày hết hạn',
  'Missions.misFilterUsers': 'Tài khoản phản hồi',
  'Missions.FilterAll': 'Tất cả thông tin',
  'Missions.misCreateMiss': 'Tạo nhiệm vụ',
  'Missions.nameRequired': 'Tạo tên cho nhiệm vụ',
  'Missions.typeRequired': 'Chọn loại nhiệm vụ',
  'Missions.zoneRequired': 'Chọn khu vực',
  'Missions.misZones': 'Khu vực',
  'Missions.misValidation3': 'Mô tả nhiệm vụ',
  'Missions.misDateExpiration': 'Ngày hết hạn',
  'Missions.dateRequired': 'Thêm ngày hết hạn',
  'Missions.selectDate': 'Chọn ngày ',
  'Missions.misBudget': 'Ngân sách',
  'Missions.misPoints': 'Bao gồm điểm?',
  'Missions.typePrize': 'Phần thưởng',
  'Missions.misPointsResponse': 'Số điểm có được khi trả lời câu hỏi',
  'Missions.excerpt': 'Tóm tắt',
  'Missions.publish': 'Gửi',
  'Missions.error1': 'Bạn cần điềm đầy đủ thông tin',
  'Missions.error2': 'Vài lựa chọn câu trả lời còn trống ',
  'Missions.success': 'Nhiệm vụ được đưa lên thành công',
  'Missions.modal': 'Bạn muốn gửi nhiệm vụ?',
  'Missions.deleteOption': 'Bạn muốn loại bỏ các tuỳ chọn này?',
  'Missions.required2Options': 'Phải có ít nhất 2 loại phản hồi',
  'Missions.selectCorrect': 'Bạn phải chọn câu trả lời đúng',
  'Missions.saveItem': 'Lưu thành công',
  'Missions.errorSave': 'Các thông tin cần phải điền đầy đủ',
  'Missions.newOptions': 'Lựa chọn mới',
  'Missions.saveOptions': 'Lưu',
  'Missions.responseText': 'Phản hồi bằng thông tin',
  'Missions.responseNumber': 'Phản hồi bằng số',
  'Missions.fileToMision': 'Tải file nhiệm vụ lên',
  'Missions.insertText': 'Nhập câu trả lời',
  'Missions.typeContent': 'Loại file',
  'Missions.type1': 'Hình ảnh',
  'Missions.type2': 'Chữ',
  'Missions.type3': 'Hồ sơ',
  'Missions.type4': 'MP4',
  'Missions.question': 'Câu hỏi',
  'Missions.optionsAll': 'Hình, chữ, File, chữ',
  'Missions.tipeOfResponse': 'Hình thức phản hồi',
  'Missions.tipeOfResponse1': 'Nhiều lựa chọn',
  'Missions.tipeOfResponse2': 'Một lựa chọn',
  'Missions.tipeOfResponse3': 'Phản hồi bằng thông tin',
  'Missions.tipeOfResponse4': 'Phản hồi bằng số',
  'Missions.insertQuestion': 'Phản hồi bằng số',
  'Missions.confirmDelete': 'Bạn muốn xoá câu hỏi này?',
  'Missions.newQuestion': 'Câu hỏi mới',
  'Missions.BudgetRemaining': 'Ngân sách còn lại',
  'Missions.TotalUsers': 'Tổng tài khoản',
  'Missions.listStores': 'Danh sách cửa hàng đã hoàn thành nhiệm vụ',
  'Missions.ResendMission': 'Gửi lại nhiệm vụ ',
  'Missions.resendConfirm': 'Bạn muốn gửi lại nhiệm vụ?',
  'Missions.sendOK': 'Bảng khảo sát đã được gửi lại',
  'Missions.sendError': 'Lỗi xảy ra khi gửi lại bảng khảo sát, vui lòng thử lại',
  'Missions.icon': 'Hình nhiệm vụ',
  'Missions.filterByProvider': 'Lọc theo nhà cung',
  'Missions.responses': 'Phản hồi',
  'Missions.confirmDownload': 'Tải về file trả lời',
  'Missions.buttonDownload': 'Tải về filetrả lời bằng file XIXS ',
  'Missions.subQuestions': 'Tạo câu hỏi phụ',
  'Missions.listSubQuestions': 'Danh sách câu hỏi phụ',
  'Missions.deleteSubQuestions': 'Xoá câu hỏi phụ',
  'Missions.deleteSubQuestionsModal': 'Bạn muốn xoá nhóm câu hỏi phụ?',
  'Missions.addSubQuestion': 'Câu hỏi phụ mới',
  'Missions.quantityLimitUser': 'Giới hạn phản hồi',
  'Missions.available': 'Nhiệm vụ có sẵn',
  'Missions.prize0': 'Không có',
  'Missions.prize1': 'Qoins',
  'Missions.prize2': 'Các đối tượng',
  'Missions.namePrize': 'Tên phần thưởng',
  'Missions.listOfQuestions': 'Danh sách các câu hỏi',
  'Missions.ListOfAwards': 'Danh sách giải thưởng',
  'Missions.quantityInInventory': 'số lượng trong kho',
  'Missions.languageES': '🇪🇸',
  'Missions.languageEN': '🇺🇸',
  'Missions.languageVI': '🇻🇳',
  'Missions.errorNoLanguage': 'Chọn ít nhất một ngôn ngữ để tạo nhiệm vụ',
  'Missions.errorNoLanguageProvider': 'Bạn phải có ngôn ngữ chính của nhà cung đã chọn',

  'Notifications.title': 'Thông báo',
  'Notifications.ListNotifications': 'Danh sách thông báo',
  'Notifications.newNotification': 'Tạo danh sách thông báo mới ',
  'Notifications.simple': 'Thông báo đại trà',
  'Notifications.advert': 'Thông báo khi sử dụng app',
  'Notifications.product': 'Sản phẩm',
  'Notifications.url': 'URL',
  'Notifications.titleTable': 'Tên thông báo',
  'Notifications.accion': 'Chọn',
  'Notifications.newNotifications': 'Tạo thông báo',
  'Notifications.requireName': 'Vui lòng điền tên thông báo',
  'Notifications.requireDesc': 'Vui lòng điền tên thông báo',
  'Notifications.public': 'Thông báo được gửi thành công',
  'Notifications.advertImage': 'Hình',
  'Notifications.requideImage': 'Vui lòng tải hình lên',
  'Notifications.advertTextImage2': 'Cập nhật hình ảnh thương hiệu',
  'Notifications.simpleSelections': 'Chọn:',
  'Notifications.requireZone': 'Vui lòng chọn khu vực',
  'Notifications.documentUser': 'Nhập email tài khoản',
  'Notifications.simpleSendForall': 'Tất cả',
  'Notifications.storesTo': 'Tất cả cửa hàng',
  'Notifications.requireImage': 'Nhập hồ sơ tài khoản',
  'Notifications.simpleSendFor': 'Gửi qua',
  'Notifications.requireFor': 'Chọn tài khoản gửi thông báo',
  'Notifications.simpleSendForZones': 'Khu vực',
  'Notifications.simpleSendForUsers': 'Tài khoản - Email',
  'Notifications.simpleSendForUsersPhone': 'Tài khoản  - Số điện thoại',
  'Notifications.simpleSendForFile': 'File CSV',
  'Notifications.deepLink': 'Deep Link',
  'Notifications.productEan': 'Mã sản phẩm',
  'Notifications.requireEan': 'Vui lòng nhập mô tả sản phẩm',
  'Notifications.uploadFile': 'Thêm file CSV với địa chỉ email để gửi thông báo ',
  'Notifications.errorEmails': 'Email không hợp lệ',
  'Notifications.list': 'Email không hợp lệ',
  'Notifications.listModal': 'Danh sách email',
  'Notifications.listInput': 'Các địa chỉ email cách nhau dấu (,) và không có khoản trống',
  'Notifications.listInputPhones': 'Các số điện thoại cách nhau dấu (,) và không có khoản trống',
  'Notifications.confirm': 'Bạn chắc chắn muốn gửi thông báo?',
  'Notifications.analysis': 'Phân tích',
  'Notifications.totalDelivered': 'Tổng thông báo đã gửi ',
  'Notifications.totalOpened': 'Số lượng app xem thông báo',
  'Notifications.PostNow': 'Gửi ngay',
  'Notifications.scheduleNotification': 'Lịch thông báo',
  'Notifications.selectTime': 'Chọn thời gian',
  'Notifications.double': 'Nhân đôi thông báo',
  'Notifications.banner': 'bảng quảng cáo',
  'Notifications.publishIn': 'Thông báo cho:',
  'Notifications.schedule': 'Lịch trình',
  'Notifications.Directly': 'Trực tiếp',
  'Notifications.publishType': 'Hình thức thông báo',
  'Notifications.combined': 'Kết hợp sản phẩm',
  'Notifications.allUsersNoCompleted': 'Chỉ dành cho tài khoản chưa hoàn tất khảo sát',
  'Notifications.allProviders': 'Các nhà cung kết nối với nhiệm vụ',
  'Notifications.smsButton': 'Tin nhắn mới',
  'Notifications.message': 'Tin nhắn',
  'Notifications.AllTheCompanies': 'Tất cả công ty của',
  'Notifications.SelectCompanies': 'Chọn công ty',
  'Notifications.SelectedCompanies': 'Chọn công ty',
  'Notifications.SendTo': 'Gửi đến',
  'Notifications.seeCompanies': 'Kiểm tra công ty',
  'notifications.sound': 'Âm thanh thông báo',
  'notifications.soundDefault': 'Âm thanh mặc định',
  'notifications.soundQuqo': 'Âm thanh Quqo',
  'notifications.link': 'Link',
  'notifications.mission': 'Nhiệm vụ',
  'notifications.appSections': 'Vùng',

  'campaigns.title': 'Chương trình mới',
  'campaigns.subTitle': 'Danh sách chương trình',
  'campaigns.new': 'Chương trình mới',
  'campaigns.new1': 'Chương trình mới',
  'campaigns.appType': 'Danh mục ứng dụng',
  'campaigns.selectChannel': 'Chọn kênh',
  'campaigns.createCampaign': 'Bạn chắc chắn tạo chương trình này?',
  'campaigns.createLoading': 'Tạo chương trình',
  'campaigns.announce': 'Thông báo khi sử dụng app',
  'campaigns.promotion': 'Khuyến mãi',
  'campaigns.promotion&ids': 'Chi tiết khuyến mãi',
  'campaigns.department&ids': 'Ngành',
  'campaigns.category&ids': 'Mục',
  'campaigns.brand&ids': 'Nhãn hiệu',
  'campaigns.search': 'Tìm kiếm',
  'campaigns.scan': 'Mã hàng',
  'campaigns.product&ids': 'Chi tiết sản phẩm',
  'campaigns.combined_product&ids': 'Kết hợp sản phẩm',
  'campaigns.mission': 'Nhiệm vụ',
  'campaigns.mission&ids': 'Chi tiết nhiệm vụ',
  'campaigns.qoin': 'Qoins của tôi',
  'campaigns.reward': 'Phần thưởng của tôi',
  'campaigns.qpay': 'Qpay',
  'campaigns.support': 'Hỗ trợ',
  'campaigns.order': 'Đơn hàng',
  'campaigns.order&ids': 'Chi tiết đơn hàng',
  'campaigns.review': 'Đánh giá chúng tôi',
  'campaigns.account': 'Tài khoản của tôi',
  'campaigns.channel': 'Kênh',

  'Inventory.ListInventory': 'Danh sách tồn kho',
  'Inventory.tableInventory': 'Bảng tồn kho',
  'Inventory.name': 'Tên sản phẩm',
  'Inventory.title': 'Tồn kho',
  'Inventory.select4': 'Hoá đơn',
  'Inventory.select5': 'Nhà cung cấp',
  'Inventory.addProduct': 'Thêm sản phẩm',
  'Inventory.subtract': 'Thêm hàng vào sản phẩm',
  'Inventory.addStock': 'Trừ hàng tồn kho cho sản phẩm',
  'Inventory.messageloading': 'Đang tải sản phẩm tồn kho',
  'Inventory.messageloading2': 'Thêm sản phẩm vào kho',
  'Inventory.messageloading3': 'Trừ sản phẩm tồn kho',
  'Inventory.selectRetailer': 'Chọn nhà cung',
  'Inventory.productId': 'Mã sản phẩm',
  'Inventory.requirePrice': 'Vui lòng nhập giá sản phẩm',
  'Inventory.addProductBuy': 'Mua',
  'Inventory.selectProduct': 'Chọn sản phẩm',
  'Inventory.addProducts': 'Sản phẩm',
  'Inventory.uploadFileInventory': 'Tồn kho',
  'Inventory.uploadFileCSV': 'Thêm hàng tồn kho vào file XLSX',
  'Inventory.upFile': 'Tải tồn kho',
  'Inventory.validation': 'Bạn muốn tải tồn kho mới lên?',
  'Inventory.validationConfirm': 'Bạn muốn tải file lên?',
  'Inventory.uploadFile': 'Tải lên tệp kiểm kê',
  'Inventory.downloadFile': 'Tải xuống tệp kiểm kê',
  'Inventory.missingProduct': 'Đơn hàng không đủ sản phẩm',
  'Inventory.missingProduct2': 'Sản phẩm thiếu',
  'Inventory.orderDeleteReasons': 'Đã sử dụng',
  'Inventory.orderDeleteReasons2': 'Tạm dừng',
  'Inventory.popConfirm': 'Bạn muốn đánh dấu xoá sản phẩm này?',
  'Inventory.orderSlopePicker': 'Đơn hàng chờ xử lý',
  'Inventory.orderID': 'Mã đơn hàng',
  'Inventory.download': 'Tải bảng tổng hợp',
  'Inventory.message': 'Tải đơn hàng chờ xử lý ',
  'Inventory.noProducts': 'Chuyển đơn hàng sang quy trình chuẩn bị hàng hoá',
  'Inventory.changeStatus': 'Đổi tình trạng đơn hàng ',
  'Inventory.download2': 'Tải danh sách hàng thiếu ',
  'Inventory.orderSlopeName': 'Tên nhân viên soạn hàng',
  'Inventory.orderSlopePrice': 'Giá',
  'Inventory.orderSlopeTotal': 'Tổng đơn vị',
  'Inventory.orderSlopeDate': 'Ngày giao hàng',
  'Inventory.orderSlopeDateCreate': 'Ngày tạo tồn kho',
  'Inventory.orderSlopeComment': 'Ghi chú',
  'Inventory.orderSlopeMark': 'Đánh dấu hoàn thành',
  'Inventory.returnOrders': 'Đơn hàng với sản phẩm trả về',
  'Inventory.orderSlopeReturnDownload': 'Tải đơn hàng với sản phẩm trả về',
  'Inventory.returnRecord': 'Đăng ký trả hàng',
  'Inventory.forInventory': 'Dành cho kiểm kê kho',
  'Inventory.DamagedProducts': 'Sản phẩm hư hỏng',
  'Inventory.ProductsNotReceived': 'Sản phẩm chưa nhận',
  'Inventory.confirmText': 'Bạn đã nhận sản phẩm. Xác nhận',
  'Inventory.Return': 'Trả hàng',
  'Inventory.ReturnTableDetail': 'Chi tiết trả hàng',
  'Inventory.ReturnDetail': 'Chi tiết trả hàng',
  'Inventory.CommittedAmount': 'Số tiền có thể mất',
  'Inventory.ToInventory': 'Lưu kho',
  'Inventory.DamagedProduct': 'Sản phẩm hư hỏng',
  'Inventory.DeliveredtoShopkeeper': 'Giao hàng cho chủ tiệm',
  'Inventory.OriginalQuantity': 'Số lượng ban đầu',
  'Inventory.Amounttobereturned': 'Số tiền hoàn trả',
  'Inventory.noDevolutions': 'Đơn hàng không có sản phẩm hoàn trả',
  'Inventory.ActualQuantity': 'Số lượng thực tế',
  'Inventory.inicialQuantity': 'Số lượng đặt hàng',
  'Inventory.productMissing': 'Đơn hàng với sản phẩm thiếu',
  'Inventory.earringsPicker': 'Số đơn hàng mà nhân viên soạn hàng chưa xong',
  'Inventory.ReturnOrdersTitle': 'Đơn hàng với sản phẩm trả về',
  'Inventory.Message': 'Tải đơn hàng chờ xử lý ',
  'Inventory.update': 'Hàng tồn được cập nhật thành công',
  'Inventory.cancel': 'Sản phẩm đã được huỷ',
  'Inventory.last30days': '30 ngày gần đây',
  'Inventory.DEVOLUCION_PROVEEDOR': 'Trả hàng cho nhà cung',
  'Inventory.AVERIA': 'Sản phẩm hư hỏng',
  'Inventory.VENCIDO': 'Sản phẩm quá hạn',
  'Inventory.VENTA_INTERNA': 'Bán cho nội bộ',
  'Inventory.CONSUMO_INTERNO': 'Sử dụng nội bộ',
  'Inventory.INGRESO_ERRÓNEO': 'Sản phẩm sai',
  'Inventory.quantityInOrders': 'Số lượng đã được đặt',
  'Inventory.virtualQuantity': 'Số lượng ảo',
  'Inventory.virtualQuantityFilter1': 'Tình trạng thực tế',
  'Inventory.virtualQuantityFilter2': 'Thay đổi mới',
  'Inventory.virtualQuantityFilter3': 'Không thay đổi',
  'Inventory.nit': 'Giấy phép số: ',
  'Inventory.lastMovement': 'Chuyển động cuối cùng',
  'Inventory.uploadInventoryDesc': 'Tải trọng này chỉ hoạt động như một phép trừ và cộng trong khoảng không quảng cáo của các sản phẩm được tải trong excel, điều quan trọng cần nhớ. Sẽ có các giao dịch tương ứng với các phép cộng và trừ đã nói',

  'Brands.Brand': 'Nhãn hiệu',
  'Brands.Brand1': 'Nhãn hiệu',
  'Brands.tittle': 'Danh sách nhãn hiệu',
  'Brands.search': 'Tìm tên',
  'Brands.createBrands': 'Thêm nhãn hiệu',
  'Brands.notImage': 'Nhãn hiệu này chưa có hình',
  'Brands.createBrand': 'Tạo nhãn hiệu',
  'Brands.uploadBrand': 'Cập nhật nhãn hiệu',
  'Brands.createBrandsId': 'Mã số nhãn hiệu',
  'Brands.createBrandUpdate': 'Cập nhật tên',
  'Brands.createBrandImage': 'Hình',
  'Brands.createBrandImageRequired': 'Vui lòng nhập giá sản phẩm',
  'Brands.createBrandUploadImage2': 'Cập nhật hình ảnh thương hiệu',
  'Brands.createBrandNameRequired': 'Vui lòng nhập tên nhãn hiệu',
  'Brands.BrandImage': 'Hình nhãn hiệu',
  'Brands.uploadFileBrands': 'Tải nhãn hiệu',
  'Brands.uploadFileSubtitle': 'Thêm nhãn hiệu mới bằng file CSV',
  'Brands.createBrandsUpload': 'Bạn muốn tải nhãn hiệu mới bằng file XLSX?',
  'Brands.finish': 'File được tải lên thành công',

  'Zones.zone': 'Quận',
  'Zones.list': 'Danh sách',
  'Zones.Maps': 'Bản đồ',
  'Zones.searchName': 'Tìm theo tên',
  'Zones.search': 'Tìm kiếm',
  'Zones.Sincronizar': 'Đồng bộ hoá cửa hàng',
  'Zones.addZone': 'Thêm quận',
  'Zones.Sincronizar1': 'Bạn có chắc đã đồng bộ hoá các khu vực cửa hàng của',
  'Zones.Sincronizar2': 'Đồng bộ hoá cửa hàng theo quận',
  'Zones.selectZonesStores': 'Chọn khu vực thể hiện cửa hàng',
  'Zones.selectZone': 'Chọn khu vực',
  'Zones.country': 'Quốc gia',
  'Zones.change': 'Thay đổi kinh độ và vĩ độ',
  'Zones.question': 'Bạn muốn huỷ hay thay đổi?',
  'Zones.RequiredName': 'Vui lòng nhập tên khu vực',
  'Zones.RequiredLocalidad': 'Vui lòng nhập địa phương ',
  'Zones.form': 'Khu vực ',
  'Zones.createZone': 'Khu vực được tạo thành công',
  'Zones.Update': 'Khu vực được tạo thành công',
  'Zones.listStores': 'Danh sách cửa hàng',
  'Zones.editConfigZone': 'Chỉnh sửa cài đặt khu vực',

  'holidays.holidays': 'Ngày lễ',
  'holidays.listHolidays': 'Danh sách ngày lễ',
  'holidays.addHolidays': 'Thêm ngày lễ',
  'holidays.new': 'Ngày lễ mới',
  'holidays.edit': 'Chỉnh sửa ngày lễ',
  'holidays.requiredDate': 'Yêu cầu ngày',
  'holidays.alertSuccess': 'Yêu cầu ngày',
  'holidays.updateSuccess': 'Ngày lễ đã được cập nhật',
  'holidays.create': 'Tạo ngày lễ mới',
  'holidays.update': 'Cập nhật ngày',
  'holidays.confirmDelete': 'Bạn muốn huỷ ngày lễ?',
  'holidays.actual': 'Hiện tại',
  'holidays.alertdelete': 'Ngày lễ đã được bỏ thành công',
  'holidays.dayHoliday': 'Ngày lễ',

  'providers.title': 'Nhà cung cấp',
  'providers.list': 'Danh sách nhà cung',
  'providers.create': 'Mẫu thông tin nhà cung',
  'providers.daylyCut': 'Thời gian nhận đơn hàng cuối cùng trong ngày ',
  'providers.image': 'Hình nhà cung',
  'providers.minAmount': 'Số lượng tối thiểu',
  'providers.pointValue': 'Điểm thưởng',
  'providers.laborableDays': 'Ngày làm việc',
  'providers.laborableDeliveryDays': 'Ngày giao hàng khó khăn',
  'providers.appReference': 'App Reference',
  'providers.currency': 'Loại tiền',
  'providers.initialBonus': 'Điểm được thưởng',
  'providers.pointsValue': 'Giá trị mỗi điểm thưởng',
  'providers.deliveryDays': 'Ngày giao hàng',
  'providers.delivery_information': 'Thông tin giao hàng',
  'providers.billing_information': 'Thông tin thanh toán',
  'providers.language': 'Ngôn ngữ',
  'providers.emailPDF': 'Email',
  'providers.confirm': 'Bạn muốn gửi thông tin này cho nhà cung?',
  'providers.earningRate': 'Giá trị điểm thưởng',
  'providers.minPoints': 'Điểm tối thiểu được dùng',
  'providers.loading': 'Tạo nhà cung',
  'providers.loadingUpdate': 'Đang cập nhật nhà cung cấp...',
  'providers.successAlert': 'Một nhà cung mới vừa được thêm vào',
  'providers.successEditAlert': 'Nhà cung đã được cập nhật',
  'providers.buttonSend': 'Gửi',
  'providers.max_slots_calendar': 'Số ngày có thể chọn',
  'providers.type_of_shipping_cost': 'Loại chi phí vận chuyển',
  'providers.default_shipping_cost': 'Phí giao hàng',
  'providers.big_amount': 'Giá trị đơn hàng cần xem lại',
  'providers.daily_cut': 'Thời gian không nhận đơn hàng cho hôm nay',
  'providers.max_orders_day': 'Số lượng đơn hàng tối đa mỗi ngày',
  'providers.max_delivery_days': 'Số ngày giới hạn nhận hàng',
  'providers.parent_provider': 'Nhà cung chính',
  'providers.min_boxes': 'Số lượng tối thiểu',
  'providers.catalog_boxes': 'Số lượng tối thiểu?',
  'providers.timezone': 'Giờ quốc gia',
  'providers.inheritance_distance': 'Giao hàng theo khoảng cách',
  'providers.type_coverage': 'Chọn hình thức mở khu vực',
  'providers.delivery_push_notification': 'Thông báo trước khi giao hàng 1 ngày',
  'providers.poits_form': 'Điểm',
  'providers.polygono': 'Khu vực',
  'providers.show_powered_by': 'Hiển thị được cung cấp bởi',
  'providers.block_assign_pilot_order_before_date': 'Chặn trong phân công cho các phi công',
  'providers.help': 'Tên nhà cung',
  'providers.help1': 'Chọn quốc gia cho nhà cung',
  'providers.help2': 'Chọn thêm nhà cung chính nếu đó là nhà cung phụ ',
  'providers.help3': 'Chọn giờ của khu vực nhà cung',
  'providers.help4': 'Nhập loại tiền của quốc gia',
  'providers.help5': 'Nhập đơn hàng tối thiểu cho nhà cung',
  'providers.help6': 'Nhập điểm thưởng cho tài khoản mới',
  'providers.help7': 'Nhập giá trị điểm thưởng',
  'providers.help8': 'Nhập giá trị điểm thưởng từ điểm sang tiền',
  'providers.help9': 'Nhập giá trị tối thiểu của điểm thưởng cho đơn hàng',
  'providers.help10': 'Chọn ngôn ngữ cho tài khoản',
  'providers.help11': 'Nhập email để gửi file pdf, phân chia các email bằng dấu (,)',
  'providers.help12': 'Số ngày thể hiện trên lịch',
  'providers.help13': 'Nhập phí vận chuyển nếu đơn hàng không đạt giá trị tối thiểu',
  'providers.help14': 'Nhập số tiền để nhận cảnh báo đơn hàng đạt giá trị tối đa qua email',
  'providers.help15': 'Số lượng đơn hàng tối đa mỗi ngày',
  'providers.help16': 'Nhập ngày giao hàng',
  'providers.help17': 'Nhà cung có yêu cầu số lượng tối thiểu?',
  'providers.help18': 'Nếu nhà cung yêu cầu số lượng tối thiểu, là bao nhiêu?',
  'providers.help19': 'Nhập số ngày tối đa nhà cung chấp nhận giao hàng',
  'providers.help20': 'Nhập số ngày làm việc được phân cách bởi dấu phẩy (,) 0,1,2,3,4,5,6 Thứ Hai: 1,Thứ Ba: 2…Chủ Nhật: 0',
  'providers.help21': 'Nhập giờ nhận đơn hàng cuối trong ngày của nhà cung theo định dạng 24h',
  'providers.help22': 'Tình trạng nhà cung: mở hoặc tắt',
  'providers.help23': 'Nhập hình thức giao hàng theo mét, ví dụ: 30',
  'providers.help24': 'Chọn thông báo khách hàng 1 ngày trước khi nhận hàng',
  'providers.help25': 'Mở / tắt sử dụng điểm cho nhà cung',
  'providers.help26': 'Chọn khu vực lân cận với thành phố',
  'providers.help27': 'Chọn hình thức mở khu vực (vị trí, khoảng cách bằng mét)',
  'providers.help28': 'Chọn khu vực nhà cung hoạt động',
  'providers.help29': 'Nhập số lượng tối thiểu cho đơn hàng mua lại',
  'providers.help30': 'Hiển thị được cung cấp bởi tên trên các sản phẩm.',
  'providers.help31': 'Chặn các nhiệm vụ đặt hàng cho phi công trước ngày giao hàng',
  'providers.help32': 'Chọn loại chi phí vận chuyển: Cố định = Giá như nhau cho bất kỳ đơn hàng nào. Phần trăm = Tổng phần trăm của đơn đặt hàng',
  'providers.help33': 'Nhập ngày giao hàng làm việc cho nhà cung cấp này',
  'providers.help34': 'Ingresa el nombre de la aplicación en la que estara disponible el proveedor',
  'providers.polygonUpdateConfirm': 'Bạn có chắc muốn cập nhật khu vực hoạt động của nhà cung này? ',
  'providers.selectPolygon': 'Chọn vùng',
  'providers.minAmountRepurchase': 'Số lượng tối thiểu cho đơn hàng mua lại',
  'providers.flat': 'Bằng phẳng',
  'providers.percentage': 'Phần trăm',

  'retailers.title': 'Nhà bán hàng',
  'retailers.list': 'Danh sách nhà bán hàng',
  'retailers.create': 'Thêm nhà bán hàng mới',
  'retailers.isWholesaler': 'Nhà bán sỉ',
  'retailers.confirm': 'Bạn muốn gửi chi tiết của nhà cung này',
  'retailers.successAlert': 'Nhà cung được cập nhật thành công',
  'retailers.save': 'Lưu thông tin',

  'proccess.title': 'Quy trình soạn hàng',
  'proccess.subtitle': 'Danh sách quy trình soạn hàng',
  'proccess.send1': 'Bạn chắc chắn muốn tạo quy trình?',
  'proccess.send2': 'Đến ngày:',
  'proccess.error': 'Bạn phải chọn ngày để tiếp tục ',
  'proccess.error2': 'Hãy đảm bảo không có quy trình kích hoạt',
  'proccess.success': 'Quy trình được tạo thành công',
  'proccess.selectDate': 'Soạn đơn hàng để giao theo ngày',
  'proccess.routeOptimization': 'Tối ưu hoá tuyến đường',
  'proccess.ListRoute': 'Danh sách tuyến đường',
  'proccess.route': 'Tuyến đường',
  'proccess.procces': 'Quy trình',
  'proccess.rute': 'Tuyến đường cho nhân viên giao hàng',
  'proccess.stimatedTime': 'Thời gian dự kiến',

  'inventoryprocess.title': 'Quy trình kiểm kê tồn kho',
  'inventoryprocess.subtitle': 'Danh sách quy trình kiểm kê tồn kho',
  'inventoryprocess.sure': 'Quy trình kiểm tồn kho sẽ được tạo cho hôm nay',
  'inventoryprocess.turnOffMessage': 'Bạn muốn tắt quy trình?',
  'inventoryprocess.turnOnMessage': 'Bạn muốn bắt đầu quy trình?',
  'inventoryprocess.restartMessage': 'Bạn muốn khởi động lại quy trình?',
  'inventoryprocess.turnOffMessageOK': 'Quy trình được đóng thành công',
  'inventoryprocess.restartMessageOK': 'Quy trình được khởi động thành công',
  'inventoryprocess.printMessage': 'Bạn muốn in đơn hàng này?',
  'inventoryprocess.printMassive': 'In tất cả',

  'picker.available': 'Chưa chỉ định',
  'picker.pending': 'Chưa hoàn tất',
  'picker.pending1': 'Chưa hoàn tất',
  'picker.inProcess': 'Đang thực hiện',
  'picker.Finished': 'Hoàn tất',
  'picker.Finished1': 'Hoàn tất',
  'picker.Print': 'In',
  'picker.Forwarded': 'Quy trình được chuyển đến nhân viên soạn hàng',
  'picker.Restart': 'Khởi động quy trình',
  'picker.changeStatusMessage': 'Bạn muốn thay đổi tình trạng?',
  'picker.NoEdit': 'Đơn hàng không thể chỉnh sửa',
  'picker.OverdueOrders': 'Đơn hàng quá hạn',
  'picker.Ranking': 'Xếp hạng nhân viên soạn hàng',
  'picker.viewListRoutes': 'Danh sách tuyến đường',
  'picker.viewListOrders': 'Chi tiết đơn hàng',
  'picker.position': 'Vị trí',
  'picker.duration': 'Thời gian',
  'picker.distance': 'Khoảng cách',
  'picker.vehicle': 'Phương tiện',
  'picker.detailMarker': 'Cảnh báo. Chọn dấu hiệu để thấy chi tiết đơn hàng ',
  'picker.freeDate': 'Ngày không làm việc',

  'supplies.supplies': 'Nhà bán hàng',
  'supplies.title': 'Danh sách nhà bán hàng',
  'supplies.addSupplies': 'Thêm',
  'supplies.formSupplies': 'Mẫu nhà bán hàng',
  'supplies.buttonADD': 'Bạn có chắn chắn muốn gửi dữ liệu cho nhà bán hàng này?',
  'supplies.createLoading': 'Đang tạo nhà bán hàng',
  'supplies.updateLoading': 'Đang cập nhật nhà bán hàng',
  'supplies.deleteModal': 'Bạn muốn huỷ nhà bán hàng này?',
  'supplies.delete': 'Huỷ',

  'localities.title': 'Quận',
  'localities.titleList': 'Danh sách quận',
  'localities.display_order': 'Hiển thị đơn hàng',
  'localities.intermediate_days': 'Ngày cách khoảng',
  'localities.delivery_days': 'Ngày giao hàng',
  'localities.editModal': 'Chỉnh sửa quận',
  'localities.createModal': 'Tạo quận mới',
  'localities.addModal': 'thêm vị trí',
  'localities.createLoading': 'Đang tạo quận mới',
  'localities.EditandoLoading': 'Đang chỉnh sửa quận',
  'localities.alertSuccess': 'Quận mới được tạo thành công',
  'localities.alertSuccessEdit': 'Quận được cập nhật thành công',
  'localities.confirmText': 'Bạn có chắc chắn muốn bỏ khu vực này?',
  'localities.alertdelete': 'Khu vực được bỏ thành công',
  'localities.min_order_amount': 'Giá trị đơn hàng tối thiểu',
  'localities.min_order_repurchase_amount': 'Giá trị đơn hàng tối thiểu dành cho đơn hàng đặt lại',
  'localities.min_price_for_free_shipping': 'Giá trị đơn hàng tối thiểu để được miễn phí vận chuyển',
  'localities.first_order_min_price_for_free_shipping': 'Giá trị đơn hàng tối thiểu để được miễn phí vận chuyển đơn hàng đầu tiên',
  'localities.shipping_cost': 'Giá vận chuyển',
  'localities.customer_shipping_cost': 'Chi phí vận chuyển của khách hàng',
  'localities.shipping_cost_percent': 'Phần trăm tổng số đơn đặt hàng',
  'localities.customer_min_amount': 'Số lượng khách hàng tối thiểu',
  'localities.qtc_active': 'Qtc hoạt động?',
  'localities.available_time_qtc': 'thời gian có sẵn qtc',
  'localities.closing_reason': 'Lý do đóng cửa',
  'localities.extra_delivery_days': 'Ngày giao hàng bổ sung',
  'localities.min_amount_extra_delivery_days': 'Số ngày giao hàng bổ sung tối thiểu',
  'localities.help1': 'Tên địa phương',
  'localities.help2': 'Chọn nhà cung',
  'localities.help3': 'Nhập chi phí tối thiểu cho đơn hàng tối thiểu',
  'localities.help4': 'Nhập giá trị tối thiểu dành cho đơn hàng đặt lại',
  'localities.help5': 'Nhập giá trị tối thiểu để được miễn phí vận chuyển',
  'localities.help6': 'Nhập giá trị tối thiểu để được miễn phí cho đơn hàng đầu tiên ',
  'localities.help7': 'Nhập mã đơn hàng cho quy trình đóng gói đơn hàng',
  'localities.help8': '1 = Thứ Hai, 2 = Thứ Ba, 3 = Thứ Tư, 4 = Thứ Năm, 5 + Thứ Sáu, 6 = Thứ Bảy, ) = Chủ Nhật',
  'localities.help9': '1 = giao hàng hôm nay (12h), 2 = giao hàng ngày mai (24h), 3 = giao hàng ngày mốt (48h)',
  'localities.help10': 'Giá vận chuyển',
  'localities.help11': 'Chi phí vận chuyển của khách hàng',
  'localities.help12': 'Số lượng khách hàng tối thiểu',
  'localities.help13': 'Qtc hoạt động?',
  'localities.help14': 'thời gian có sẵn qtc',
  'localities.help15': 'Lý do đóng cửa',
  'localities.help16': 'Tỷ lệ phần trăm của đơn đặt hàng cho chi phí vận chuyển',

  'zipCodes.title': 'Mã bưu chính',
  'zipCodes.list': 'Danh sách Mã bưu chính',
  'zipCodes.create': 'Tạo mới',
  'zipCodes.placeholder': 'Tìm kiếm mã bưu chính',
  'zipCodes.titleModal': 'Tạo mã bưu chính mới',
  'zipCodes.editModal': 'Chỉnh sửa mã bưu chính',
  'zipCodes.code': 'Mã ',
  'zipCodes.codeRequired': 'Yêu cầu nhập mã',
  'zipCodes.confirmDelete': 'Bạn muốn xoá mã bưu chính này?',
  'zipCodes.createLoading': 'Tạo mã bưu chính mới',
  'zipCodes.EditandoLoading': 'Chỉnh sửa mã bưu chính',
  'zipCodes.alertSuccess': 'Thông tin được lưu thành công',
  'zipCodes.alertdelete': 'Mã bưu chính được bỏ thành công',

  'cities.title': 'Thành phố',
  'cities.titleList': 'Danh sách thành phố',
  'cities.deleteMessage': 'Bạn muốn xoá thành phố này?',
  'cities.createModal': 'Tạo thành phố',
  'cities.editCity': 'Chỉnh sửa thành phố',
  'cities.alertdelete': 'Thành phố được huỷ bỏ thành công',
  'cities.createLoading': 'Tạo thành phố',
  'cities.EditandoLoading': 'Chỉnh sửa thành phố',
  'cities.alertSuccessEdit': 'Thành phố được chỉnh sửa thành công',
  'cities.alertSuccess': 'Thành phố được tạo thành công',
  'cities.NoCountry': 'Không có quốc gia',
  'cities.showAll': 'Hiển thị thành phố không có nhà cung',

  'neighboarhood.title': 'Khu vực lân cận',
  'neighboarhood.titleList': 'Danh sách khu vực lân cận',
  'neighboarhood.createModal': 'Tạo khu vực lân cận',
  'neighboarhood.editModal': 'Chỉnh sửa khu vực lân cận',
  'neighboarhood.confirmText': 'Bạn muốn xoá khu vực lân cận này?',
  'neighboarhood.alertdelete': 'Khu vực lân cận được xoá thành công',
  'neighboarhood.alertSuccessEdit': 'Khu vực lân cận được cập nhật thành công',
  'neighboarhood.EditandoLoading': 'Chỉnh sửa khu vực lân cận',
  'neighboarhood.createLoading': 'Tạo khu vực lân cận',
  'neighboarhood.alertSuccess': 'Khu vực lân cận được tạo thành công',

  'countries.title': 'Quốc gia',
  'countries.country': 'Quốc gia ',
  'countries.titleList': 'Danh sách quốc gia',
  'countries.iso': 'ISO',
  'countries.alertdelete': 'Quốc gia đã được xoá thành công',
  'countries.editModal': 'Chỉnh sửa quốc gia',
  'countries.createModal': 'Tạo quốc gia',
  'countries.createLoading': 'Tạo quốc gia',
  'countries.alertSuccess': 'Quốc gia mới đã được tạo thành công',
  'countries.EditandoLoading': 'Chỉnh sửa quốc gia',
  'countries.alertSuccessEdit': 'Quốc gia được cập nhật tạo thành công',
  'countries.confirmText': 'Bạn muốn loại bỏ quốc gia này?',
  'countries.imageURL': 'Hình URL',

  'dailyCommitment.title': 'Số lượng tài khoản mỗi ngày',
  'dailyCommitment.subTitle': 'Số lượng tài khoản nhân viên kinh doanh phải đạt mỗi ngày',
  'dailyCommitment.activities': 'Tài khoản ',
  'dailyCommitment.commitment': 'Số lượng',
  'dailyCommitment.reactivations': 'Tài khoản cũ',
  'dailyCommitment.FirstOrder': 'Đơn hàng đầu tiên',
  'dailyCommitment.Activation': 'Tài khoản ',
  'dailyCommitment.Reactivation': 'Tài khoản cũ',

  'salesman.title': 'Nhân viên kinh doanh',
  'salesman.VisitToStores': 'Khu vực chỉ định gặp cửa hàng',
  'salesman.necessarySalesman': 'Tuyến đường cần thiết',
  'salesman.routes': 'Tuyến đường',
  'salesman.storesBySalesman': 'Số cửa hàng được giao mỗi nhân viên kinh doanh',
  'salesman.detailByProcess': 'Chi tiết khu vực chỉ định',
  'salesman.createProcess': 'Tạo khu vực chỉ định cho quy trình thăm cửa hàng',
  'salesman.salesman1': 'Mỗi nhân viên kinh doanh gặp bao nhiêu cửa hàng?',
  'salesman.createOK': 'Bạn muốn tạo khu vực chỉ định?',
  'salesman.mapModal': 'Bản đồ tuyến đường',
  'salesman.ToAssign': 'Giao nhiệm vụ',
  'salesman.ToAssignModal': 'tạo tuyến đường mới',
  'salesman.selectSalesman': 'Chọn nhân viên kinh doanh',
  'salesman.selectRequired': 'Chọn nhân viên kinh doanh cho tuyến đường cụ thể',
  'salesman.salesman': 'Nhân viên kinh doanh',
  'salesman.salesmans': 'Nhân viên kinh doanh',
  'salesman.stores': 'Cửa hàng',
  'salesman.success': 'Chỉ định tuyến đường cho nhân viên kinh doanh thành công',
  'salesman.noName': 'Không chỉ định',
  'salesman.noNumber': 'Thêm số lượng cửa hàng cần nhân viên kinh doanh ghé thăm',
  'salesman.noMore': 'Không chỉ định quá 24 cửa hàng cho 1 tuyến đường',
  'salesman.dateLastOrder': 'Ngày đặt hàng gần nhất',
  'salesman.CreatePolygon': 'Tạo khu vực chỉ định cho quy trình thăm cửa hàng',
  'salesman.Finalize': 'Hoàn tất',
  'salesman.searchStores': 'Tìm cửa hàng',
  'salesman.deleteRoute': 'Bạn muốn xoá tuyến đường?',
  'salesman.errorNoStores': 'Tuyến đường này chưa chỉ định cửa hàng ',
  'salesman.detailMap2': 'Hoàn tất bản đồ tuyến đường',
  'salesman.selecrtSalesmans': 'Chọn nhân viên kinh doanh',
  'salesman.deleteMessage': 'Bạn muốn xoá quy trình này?',
  'salesman.successDelete': 'Đã xoá quy trình',
  'salesman.createNewRoute': 'Tạo tuyến đường theo đa giác',
  'salesman.createNewRouteXlsx': 'Tạo tuyến đường theo danh sách cửa hàng',
  'salesman.createRoutesuccess': 'Tuyến đường đã được tạo thành công!',
  'salesman.detailMap': 'Hiển thị toàn bộ bản đồ',

  'givepointsOrders.title': 'Cộng điểm cho cửa hàng',
  'givepointsOrders.subTitle': 'Phương thức cộng điểm cho cửa hàng',
  'givepointsOrders.button': 'Tặng điểm',
  'givepointsOrders.detailTransactions': 'Chi tiết giao dịch',
  'givepointsOrders.textConfirm': 'Bạn có chắc muốn gửi điểm cho cửa hàng?',
  'givepointsOrders.responsable': 'Người gửi điểm',
  'givepointsOrders.noResponsable': 'Không có thông tin',
  'givepointsOrders.desc1': 'Sản phẩm bị hỏng',
  'givepointsOrders.desc2': 'Sản phẩm không đúng',
  'givepointsOrders.desc3': 'Thiếu sản phẩm',
  'givepointsOrders.desc4': 'Giá hiển thị sai',
  'givepointsOrders.desc5': 'Bồi hoàn phí vận chuyển',
  'givepointsOrders.desc6': 'Sản phẩm bị giao sai cửa hàng',

  'permissions.title': 'Phân quyền quản trị viên theo vai trò',
  'permissions.createRole': 'Tạo vai trò mới',
  'permissions.role': 'Vai trò',
  'permissions.permissions': 'Phân quyền',
  'permissions.success': 'Vai trò mới được tạo thành công',
  'permissions.popConfirm': 'Bạn có chắc muốn thay đổi quyền cho vị trí này?',
  'permissions.popConfirmUser': 'Bạn có chắc muốn thay đổi quyền cho người dùng này?',
  'permissions.exito': 'Phân quyền đã được thay đổi thành công',

  'Q2CTitle': 'Q2C',
  'Q2CTitle.detail': 'Chi tiết cửa hàng',

  'Q2CCatalog.update': 'Danh mục sản phẩm',
  'Q2CCatalog.ally': 'Chọn nhà hợp tác',
  'Q2CCatalog.locality': 'Chọn địa phương',

  'Q2COrders.status0': 'Đang xử lý',
  'Q2COrders.status1': 'Chấp nhận',
  'Q2COrders.status4': 'Đã giao hàng',
  'Q2COrders.confirm': 'Bạn chắc chắn muốn đổi tình trạng đơn hàng?',

  'registrationPurchaseOrders.title': 'Tạo đơn mua hàng',
  'registrationPurchaseOrders.approvePurchaseOrders': 'Duyệt đơn hàng',
  'registrationPurchaseOrders.approvePurchaseOrdersAllCities': 'Duyệt đơn hàng cho tất cả thành phố',
  'registrationPurchaseOrders.subTitle': 'Mẫu khởi tạo đơn hàng',
  'registrationPurchaseOrders.listTitle': 'Danh sách đơn mua hàng',
  'registrationPurchaseOrders.confirmDelete': 'Bạn có chắc muốn xoá sản phẩm này?',
  'registrationPurchaseOrders.messageProducts': 'Bạn phải lưu tất cả sản phẩm trước khi tiếp tục',
  'registrationPurchaseOrders.addRegister': 'Thêm đơn mua hàng',
  'registrationPurchaseOrders.saveOrder': 'Lưu đơn mua hàng',
  'registrationPurchaseOrders.warehouse': 'Chọn kho',
  'registrationPurchaseOrders.cancelOrder': 'Bạn phải chọn lí do huỷ',
  'registrationPurchaseOrders.questionModal': 'Nên lưu đơn hàng',
  'registrationPurchaseOrders.questionModal2': 'Nên cập nhật đơn hàng lưu',
  'registrationPurchaseOrders.success': 'Đơn hàng được lưu thành công',
  'registrationPurchaseOrders.confirmAproved': 'Bạn muốn duyệt đơn hàng này?',
  'registrationPurchaseOrders.confirmCancel': 'Bạn muốn huỷ đơn hàng này?',
  'registrationPurchaseOrders.confirmDelivered': 'Bạn muốn huỷ đơn hàng này?',
  'registrationPurchaseOrders.confirmDelivered2': 'Các sản phẩm sẽ được ghi chú đã nhận',
  'registrationPurchaseOrders.confirmSuccesfull': 'Đơn hàng được giao thành công',
  'registrationPurchaseOrders.buttonSend': 'Đánh dấu đơn hàng đã nhận',
  'registrationPurchaseOrders.confirmAprovedSuccess': 'Đơn hàng được đánh dấu đã duyệt',
  'registrationPurchaseOrders.confirmAprovedCancel': 'Đơn hàng được đánh dấu đã huỷ',
  'registrationPurchaseOrders.detailOrder': 'Chi tiết đơn hàng',
  'registrationPurchaseOrders.totalReceived': 'Tổng số nhận',
  'registrationPurchaseOrders.invoiceRequired': 'Bạn cần thêm số hoá đơn để chuyển đơn hàng sang trạng thái đã giao hàng',
  'registrationPurchaseOrders.updateOrder': 'Cập nhật đơn hàng',
  'registrationPurchaseOrders.noSaved': 'Sản phẩm không đúng với lĩnh vực yêu cầu',
  'registrationPurchaseOrders.EditAndreceive': 'Chỉnh sửa và nhận',
  'registrationPurchaseOrders.receive': 'Nhận sản phẩm',
  'registrationPurchaseOrders.DeliveryType': 'Hình thức giao hàng',
  'registrationPurchaseOrders.PartialDelivery': 'Giao hàng từng phần',
  'registrationPurchaseOrders.FinalDelivery': 'Giao tất cả',
  'registrationPurchaseOrders.ConfirmDelivery': 'Xác nhận đơn hàng',
  'registrationPurchaseOrders.recivedProduct': 'Bạn có chắc muốn nhận sản phẩm này?',
  'registrationPurchaseOrders.observationNull': 'Bạn cần ghi chú sự thay đổi ',
  'registrationPurchaseOrders.typeNull': 'Bạn cần chọn hình thức giao hàng',
  'registrationPurchaseOrders.InvoiceNull': 'Đơn hàng này không có số hoá đơn',
  'registrationPurchaseOrders.InvoiceMessage': 'Thêm số đơn hàng và cập nhật đơn hàng để tiếp tục…',
  'registrationPurchaseOrders.succesItem': 'Sản phẩm đã được thêm thành công',
  'registrationPurchaseOrders.noProducts': 'Không tìm thấy sản phẩm',
  'registrationPurchaseOrders.messageError': 'Vui lòng thêm mô tả sản phẩm',
  'registrationPurchaseOrders.note': 'GHI CHÚ: Sản phẩm tô vàng phải có thông tin mô tả sản phẩm để được thêm vào đơn hàng ',
  'registrationPurchaseOrders.addDiscount': 'Thêm khuyến mãi',
  'registrationPurchaseOrders.addDiscount2': 'Thêm khuyến mãi vào đơn hàng',
  'registrationPurchaseOrders.followingPurchaseOrder': 'Đơn hàng đã được tạo thành công',
  'registrationPurchaseOrders.confirmAddDiscount': 'Bạn muốn thêm khuyến mãi vào đơn hàng?',
  'registrationPurchaseOrders.errorRecibeOrder': 'Sản phẩm đã nhận trên hệ thống',
  'registrationPurchaseOrders.runBillingByDateRange': 'Hiển thị đơn theo ngày',
  'registrationPurchaseOrders.range': 'Chọn theo ngày',
  'registrationPurchaseOrders.selectProviders': 'Chọn nhà cung cấp',
  'registrationPurchaseOrders.confirmModalRanges': 'Bạn muốn hiển thị đơn hàng theo ngày?',
  'registrationPurchaseOrders.successCreateRanges': 'Lưu thành công',
  'registrationPurchaseOrders.selectFileXlsx': 'Chọn tệp sản phẩm (XLSX)',

  'r.form.billingInformation': 'Thông tin đơn hàng',
  'r.form.deliveryInformation': 'Thông tin giao hàng',
  'r.form.approved': 'Đã duyệt',
  'r.form.observations': 'Ghi chú',
  'r.form.originalDeliveryDate': 'Ngày giao hàng  đã chọn',
  'r.form.deliveryDate': 'Ngày giao hàng',
  'r.form.eanSales': 'EAN',
  'r.form.eanPacking': 'Mã số đóng gói',
  'r.form.packing': 'Đóng gói',
  'r.form.totalTaxes': 'Tổng thuế',
  'r.form.subTotalAmount': 'Tổng cộng',
  'r.form.newProduct': 'Sản phẩm mới',
  'r.form.InventoryDays': 'Số ngày lưu kho',
  'r.status.0': 'Nháp',
  'r.status.1': 'Chờ duyệt',
  'r.status.2': 'Đã duyệt',
  'r.status.3': 'Đã huỷ',
  'r.status.4': 'Đã nhận',
  'r.status.5': 'Đã hoàn thành',

  'appComparative.title': 'Báo cáo sản phẩm bán tốt nhất',
  'appComparative.createReport': 'Tạo báo cáo',
  'appComparative.createReportQuestion': 'Bạn muốn tạo báo cáo mới?',
  'appComparative.createReportSuccess': 'Một bản báo cáo mới đã được tạo, vui lòng nhấn để thêm thông tin!',
  'appComparative.creator': 'Người tạo báo cáo',
  'appComparative.reportDetail': 'Chi tiết báo cáo',
  'appComparative.priceComparison': 'So sánh giá',
  'appComparative.app': 'Ứng dụng',
  'appComparative.addApp': 'Thêm ứng dụng',
  'appComparative.questionSend': 'Bạn chắc chắn muốn lưu?',
  'appComparative.updateSuccess': 'Thông tin được cập nhật thành công!',
  'appComparative.sendEmailButton': 'Gửi email',
  'appComparative.detailModal': 'Chi tiết bảng so sánh giá',
  'appComparative.cancelEditModal': 'Bạn muốn huỷ bỏ thông tin chỉnh sửa?',

  'coupons.title': 'Coupon',
  'coupons.subTitle': 'Danh sách coupon',
  'coupons.buttonAdd': 'Tạo Coupon',
  'coupons.titleForm': 'Mẫu coupon',
  'coupons.coupon': 'Coupon',
  'coupons.prizeType': 'Phân loại giải thưởng',
  'coupons.prize': 'Giá trị coupon',
  'coupons.prize2': 'Phần trăm giảm giá',
  'coupons.ruleMinAmount': 'Giá trị đơn hàng tối thiểu có thể được áp dụng giảm giá',
  'coupons.ruleMaxAmount': 'Giá trị coupon tối đa được hưởng',
  'coupons.productAtLeast': 'Số lượng tối thiểu của sản phẩm cần có ',
  'coupons.target': 'Gửi đến',
  'coupons.general': 'Tất cả',
  'coupons.listUsers': 'Danh sách tài khoản',
  'coupons.type': 'Loại coupon',
  'coupons.byProducts': 'Sản phẩm trong giỏ hàng',
  'coupons.byCategory': 'Sản phẩm theo danh mục',
  'coupons.byDepartment': 'Sản phẩm theo ngành hàng',
  'coupons.monto': 'Giá trị coupon',
  'coupons.purchasePercentage': 'Phần trăm',
  'coupons.createCoupon': 'Tạo coupon',
  'coupons.couponModal': 'Bạn muốn tạo coupon?',
  'coupons.successCreate': 'Coupon được tạo thành công',
  'coupons.quantityTotal': 'Số lượng coupon ',
  'coupons.quantityTotalByUser': 'Số lượng sử dụng coupon tối đa mỗi khách hàng',
  'coupons.allCountry': 'Áp dụng coupon trên toàn quốc',
  'coupons.allCountryOK': 'Hãy nhớ rằng người dùng có thể sử dụng phiếu giảm giá ở bất kỳ nhà cung cấp nào trong nước.',

  'baskets.name': 'Rổ hàng',
  'baskets.permissionsCreate': 'Tạo rổ hàng',
  'baskets.warehouse': 'Nhà kho',
  'baskets.code': 'Mã số',
  'baskets.listName': 'Danh sách rổ hàng',
  'baskets.createButton': 'Tạo rổ hàng',
  'baskets.editButton': 'Chỉnh sửa rổ hàng',
  'baskets.selectedWarehouse': 'Chọn nhà kho',
  'baskets.confirmAlert': 'Bạn muốn tạo rổ hàng mới?',
  'baskets.confirmEditAlert': 'Bạn muốn cập nhật rổ hàng?',
  'baskets.changeWarehouse': 'Đổi kho',
  'baskets.returnBaskets': 'Trả rổ hàng',
  'baskets.returnBasketsButton': 'Trả rổ hàng',
  'baskets.pilotName': 'Tên nhân viên giao hàng',
  'baskets.return': 'Nhận rổ hàng',
  'baskets.errorCompleteProcess': 'Bạn phải nhận ít nhất 1 rổ hàng',
  'baskets.confirmDeliveryBaskets': 'Chắc chắn nhận rổ hàng',
  'baskets.openProcess': 'Bắt đầu quy trình',
  'baskets.closed': 'Đóng quy trình',
  'baskets.status0': 'Không có rổ hàng mới',
  'baskets.status1': 'Rổ hàng mất',
  'baskets.status2': 'Rổ hàng hư',
  'baskets.basket': 'Rổ hàng',
  'baskets.basketSuccessModal': 'Rổ hàng đã được hoàn trả thành công',
  'baskets.quantity': 'Số lượng rổ hàng',

  'print.name': 'IP Máy in',

  'ads.title': 'Quảng cáo',
  'ads.createAd': 'Tạo quảng cáo',
  'ads.editAd': 'Chỉnh sửa Quảng cáo',
  'ads.formAds': 'Các hình thức quảng cáo',
  'ads.inAppMesagge': 'inAppMesagge',
  'ads.acept': 'Bạn có chắc chắn tạo quảng cáo không?',
  'ads.aceptEdit': 'Bạn có chắc chắn chỉnh sửa quảng cáo không?',
  'ads.aceptEditState': 'Bạn có chắc chắn thay đổi trạng thái của quảng cáo không?',
  'ads.successCreate': 'Quảng cáo đã được tạo thành công!',
  'ads.successEdit': 'Quảng cáo đã được chỉnh sửa thành công!',
  'ads.available': 'Có sẵn',

  'orderReceipts.title': 'Biên lai đặt hàng',

  'orderTransaction.titlle': 'Lịch sử thanh toán',
  'orderTransaction.createdAt': 'Thời gain',
  'orderTransaction.amount': 'Số tiền',
  'orderTransaction.totalAmount': 'Tổng tiền',
  'orderTransaction.transacionId': 'Mã giao dịch',
  'orderTransaction.paymentMethod': 'Phương thức',
  'orderTransaction.bankAccount': 'Thông tin tài khoản',
  'orderTransaction.status': 'Trạng thái',
}

export default {
  locale: 'vi-VN',
  antdData,
  localeData,
  messages,
}
